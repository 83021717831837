import React from 'react';
import './styles.css';
import Card from '../cardItem';
import {CardHeader, CardInfo} from '../cardItem/header';
import {CardBody} from '../cardItem/body';
import { getPlacesImages } from '../../utils/constants';

const HamburgerItem = ({item, showAddress}) => {
    const displayImage = getPlacesImages(item.place);
    return (
        <Card>
            <CardHeader rank={item.rank} certified={item.certified} image={displayImage} imageAlt={item.place.name} />
            <CardBody>
                <CardInfo name={item.place.name} rating={item.weekAverage || 0} />
                {showAddress ? <label className={"ItemAddress"}><b>{`${item.distance ? Number(item.distance).toFixed(1) + 'Km' : ''}`}</b>{` - ${item.place.formatted_address}`}</label> : null}
            </CardBody>
        </Card>
    );
};

export default HamburgerItem;