import {CURRENT_LOCATION} from '../actions/types';

const INITIAL_STATE = {
	location: null
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
		case CURRENT_LOCATION:
			return {
				...state,
				location: action.payload
			}
			default:
				return state;
    }
}