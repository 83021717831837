/// This file must ever have the same content as /admin/src/config
let envs = {};
let rollbarToken = 'df68ff44e05c428386388d85b2653b91';

envs.development = {
    baseUrl: 'http://localhost:3000/api',
    rollbarToken,
    mapsToken: 'AIzaSyDKpnPb0fIzrYTsr_7jAxhJd3jM_MmJmo8'
};
envs.production = {
    baseUrl: 'https://good-burger-prod.herokuapp.com/api',
    rollbarToken,
    mapsToken: 'AIzaSyDi04czlvQ6vMOkuAedbKn0DATSyP7L-tE'
};

export default envs;
