import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router';
import Auth from '../views/login';
import { push } from 'react-router-redux';
import { listener } from '../redux/store';
import { Rollbar } from '@app-masters/js-lib';

class AuthRoute extends Component {
    constructor (props) {
        super(props);
        this.onLoginUserSuccess = this.onLoginUserSuccess.bind(this);
        this.reRouting = this.reRouting.bind(this);
        listener.on('login_user_success', this.onLoginUserSuccess);
        listener.on('login_user_fail', this.reRouting);
    }

    // componentWillMount () {
    //     this.props.isUserInLocalStorage();
    // }

    reRouting (action) {
        if (!this.props.isAuthenticated && this.props.location.pathname !== '/signup') {
            this.props.push('/');
        }
    }

    onLoginUserSuccess (action) {
        console.log(this.props.isAuthenticated);
        Rollbar.setPerson(action.payload.user.id, action.payload.user.email, action.payload.user.name);
    }
    render () {
        let component = this.props.user?this.props.component:Auth;
        return <Route exact={this.props.exact} path={this.props.path} component={component}/>
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default withRouter(connect(mapStateToProps, {/* isUserInLocalStorage, */ push})(AuthRoute));
