import React, { useMemo, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { IconButton } from 'material-ui';
import { Chip, Button, Grid, Modal } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {useWindowSize, DatesFromPlace, getPlacesImages} from '../../utils/constants';

import Calendar from '@material-ui/icons/Today';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Alarm from '@material-ui/icons/Alarm';
import Phone from '@material-ui/icons/Phone';
import Web from '@material-ui/icons/Language';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import WhatsApp from '@material-ui/icons/WhatsApp';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import Room from '@material-ui/icons/Room';

import Skeleton from '@material-ui/lab/Skeleton';
import { Http, Rollbar, AppBootstrap } from '@app-masters/js-lib';
import SideBar from '../../components/sideBar';
import moment from 'moment';
import "moment/dist/locale/pt-br";
import './styles.css';
import GoogleMapReact from 'google-map-react';
import StarRatingComponent from 'react-star-rating-component';
import ExpertMenu from './expertMenu';
import Gallery from 'react-grid-gallery';
import CertifiedDark from '../../assets/certifiedDark.png';
import { formatUrl, whatsappMessageUrl } from '../../utils/string';

import { CarouselProvider, Slider, Slide, Image, ButtonNext, ButtonBack } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

moment.locale("pt-br");

const Marker = () => <div style={{color: '#b71c1c'}}><Room color={'inherit'} fontSize={'large'} /></div>;

const DetailRating = ({label, name, rating, price}) => {

    return (
        <Grid item lg={6} sm={6} xs={6}>
            <Grid container>
                <Grid item lg={'auto'} sm={'auto'} xs={12} style={{flex: 1}}>
                    <label className={"DetailRatingLabel"}>{label}</label>
                </Grid>
                <Grid item lg={'auto'} sm={'auto'} xs={12} style={{minWidth: 120}}>
                    {!price ? <Rating
                        name={name}
                        value={rating}
                        disabled
                        precision={0.5}
                    /> :
                    <StarRatingComponent
                        name='price'
                        value={rating}
                        starCount={5}
                        starColor='#85bb65'
                        emptyStarColor='grey'
                        renderStarIcon={() => (<i style={{ fontSize: '16px', marginLeft: 3 }} className="fas fa-dollar-sign">  </i>)}
                    />}
                </Grid>
            </Grid>
        </Grid>
    );
};

const DetailAttendance = ({place, social, hamburger}) => {
    const renderDetail = (label, icon) => {
        return (
            <div className={"AttendanceItem"}>
                {icon}
                <label className={"AttendanceItemLabel"}>{label}</label>
            </div>
        );
    };

    const RenderLink = ({item}) => {
        return (
            <Button fullWidth className={"AttendanceButton"} onClick={() => window.open(item.link)} style={{backgroundColor: item.bgColor || '#FFFFFF'}}>
                <div className={"AttendanceClickableItem"}>
                    {item.icon}
                    {item.label ? <p className={"AttendanceItemLabel AttendanceLink"}>{item.label}</p> : null}
                </div>
            </Button>
        )
    }

    const GetOpeningDays = () => {
        const [firstDay, lastDay, time, dateList] = DatesFromPlace(place);
        const formattedTime = time ? time.replace(dateList[0], "").replace("AM", "").replace("PM", "") : undefined;
        return (
            <Grid item lg={12} sm={12} xs={12}>
                {firstDay && lastDay ? renderDetail(`${firstDay} a ${lastDay}`, <Calendar color={"inherit"} />) : null}
                {time && dateList ? renderDetail(formattedTime.includes("Fechado") ? "Não abre hoje" : formattedTime, <Alarm color={"inherit"} />) : null}
            </Grid>
        );
    };

    const isOpen = useMemo(() => {
        if(!place.opening_hours || !place.opening_hours.weekday_text) return false;
        const day = place.opening_hours.weekday_text[moment().day() - 1];
        if(!day || day.includes("Fechado") || day.includes("Closed")) return false;
        if(day.includes("Atendimento 24")) return true;

        const times = day.replace(day.split(":")[0] + ':', "").replace("AM", "").replace("PM", "").trim().split(" – ");

        const openingTime = times[0].split(":");
        const closeTime = times[1].split(":");

        const todayOpening = moment().set({hour: openingTime[0], minute: openingTime[1]});
        const todayClose = moment().set({hour: closeTime[0], minute: closeTime[1]});
        return moment().isAfter(todayOpening) && moment().isBefore(todayClose);
    }, [place]);
    return (
        <div className={"EvaluationContainer"}>
            <Grid container spacing={2}>
                <Grid item lg={6} sm={12} xs={12}>
                    <Grid container>
                        {isOpen ? <Chip label={"ABERTO"} className={"OpenChip"}/> : null }
                        {GetOpeningDays()}
                        {place.formatted_phone_number ? renderDetail(place.formatted_phone_number, <Phone color={"inherit"} />) : null}
                    </Grid>
                </Grid>
                <Grid item lg={4} sm={12} xs={12} style={{margin: '0px auto'}}>
                    <Grid container>
                        <Grid item lg={12} sm={12} xs={12}>
                            {social.orderOnline ? <RenderLink item={{label: 'Pedir online', icon: <ShoppingBasket />, bgColor: '#ea1d2c', link: formatUrl(social.orderOnline)}} /> : null}
                            <Grid container spacing={1}>
                                <Grid item lg={6} sm={6} xs={6}>
                                    {social.facebook ? <RenderLink item={{icon: <Facebook />, bgColor: '#0097e6', link: formatUrl(social.facebook)}} /> : null}
                                    {social.website ? <RenderLink item={{icon: <Web />, bgColor: '#33333350', link: formatUrl(place.website)}} /> : null}
                                </Grid>
                                <Grid item lg={6} sm={6} xs={6}>
                                    {social.whatsapp ? <RenderLink item={{icon: <WhatsApp />, bgColor: '#00b894', link: whatsappMessageUrl(social.whatsapp, 'Encontrei vocês no Good Burger')}} /> : null}
                                    {social.instagram ? <RenderLink item={{icon: <Instagram />, bgColor: '#8134AF', link: formatUrl(social.instagram)}} /> : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ExpertMenu hamburger={hamburger} />
        </div>
    );
};

const DetailLocation = ({place}) => {
    const API_KEY = AppBootstrap.getConfig().mapsToken;
    const defaultLocation = useMemo(() => {
        return {...place.geometry.location}
    }, [place])
    return (
        <div className={"EvaluationContainer"}>
            <Grid container>
                <Grid item lg={12} sm={12} xs={12} className={"AttendanceTitle"} style={{flexDirection: 'column'}}>
                    <label className={"LocationItemLabel"}>{place.formatted_address}</label>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <div style={{height: 150, borderRadius: 5, boxShadow: "0px 0px 5px 0px #00000050"}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: API_KEY }}
                        defaultCenter={defaultLocation}
                        defaultZoom={17}
                        >
                        <Marker {...defaultLocation} />
                        </GoogleMapReact>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const DetailGallery = ({place}) => {
    const API_KEY = AppBootstrap.getConfig().mapsToken;
    const [,,isMobile] = useWindowSize();
    const [modal, setModal] = useState({show: false, image: null});

    const gallery = useMemo(() => {
        if(!place.photos || place.photos.length === 0) return [];
        try {
            return place.photos.map(item => {
                return {
                    src: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=1600&photoreference=${item.photo_reference}&key=${API_KEY}`,
                    thumbnail: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=300&photoreference=${item.photo_reference}&key=${API_KEY}`,
                    thumbnailWidth: 280,
                    thumbnailHeight: 200,
                }
            }); 
        }
        catch(err) {
            return []
        }
    }, [place, API_KEY]);

    return isMobile ?
        <div className={"EvaluationContainer"}>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                lockOnWindowScroll
                dragEnabled={false}
                style={{position: 'relative', height: 130}}
                visibleSlides={2}
                totalSlides={gallery.length}
            >
                <Slider style={{marginLeft: 10, marginRight: 10}}>
                    {gallery.map((item, index) =>
                        <Slide key={index} style={{height: 100}} index={item} onClick={() => setModal({show: true, image: item.src})}>
                            <div className={"GalleryItem"}>
                                <Image src={item.src} style={{borderRadius: 5, height: "100%"}} />
                            </div>
                        </Slide>
                    )}
                </Slider>
                <ButtonBack className={"CarouselIcons"} style={{left: 0}}><ChevronLeft style={{color: "#FFFFFF", width: 30, height: 30}} /></ButtonBack>
                <ButtonNext className={"CarouselIcons"} style={{right: 0}}><ChevronRight style={{color: "#FFFFFF", width: 30, height: 30}} /></ButtonNext>
            </CarouselProvider>
            <Modal
                open={modal.show}
                className={"ModalGallery"}
                onClose={() => setModal({show: false, image: null})}
            >
                <div className="ModalImage">
                    <img src={modal.image} alt="detalhe" style={{borderRadius: 5, height: "100%"}} />
                </div>
            </Modal>
        </div> :
        <div className={"EvaluationContainer"}>
            <Gallery images={gallery} enableImageSelection={false} />
        </div>
};

const DetailEvaluation = ({placeId, place}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    
    const googleReviews = useMemo(() => {
        return place.reviews.map(review => {
            return {
                id: review.author_name,
                notes: review.text,
                created_at: moment.unix(review.time).toISOString(),
                user: {
                    name: review.author_name,
                },
                average: review.rating
            }
        })
    }, [place])

    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        const getData = async (retries = 0, err) => {
            if (err && retries > 1) {
                Rollbar.error('Failed to load rating list', err);
                return;
            }
            Http.get(`/rating?sort=-created_at&populate=user&hamburger=${placeId}`)
            .then(data => {
                const list = [...googleReviews, ...data].sort((a, b) => a.created_at < b.created_at ? 1 : -1);
                setData(list);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                getData(retries + 1, e)
            });
        }
        getData();
    }, [placeId, googleReviews]);

    const renderCardEval = (item, index) => {
        const cardName = item.user.name ? item.user.name : item.user.local.email ? item.user.local.email.split("@")[0].replace(/\./g, "") : 'Cliente';
        return (
            <div key={index} className={"EvaluationItem"} >
                <Grid container style={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Grid item xs={8} sm={8} md={8} lg={8} style={{overflow: "hidden", textOverflow: 'ellipsis'}}>
                        <label className={"CardItemLabel"}>{cardName}</label>
                        {item.user.data && item.user.data.expert ? <Chip label={"Expert"} size={"small"} style={{color: "#FFFFFF", backgroundColor: "#ff5252"}} /> : null}
                    </Grid>
                    <Rating
                        name={item.id}
                        value={item.average}
                        disabled
                    />
                </Grid>
                <p className={"CardItemDate"}>{moment(item.created_at).fromNow()}</p>
                <p className={"CardItemNotes"}>{item.notes}</p>
            </div>
        );
    };

    const renderList = () => {
        if (loading) return [1, 2, 3].map((item) => <Skeleton key={item} variant='rect' height={80} className={"EvaluationItem"} />);
        if (data.length === 0) return <div className={"EmptyList"}><label className={"EmptyListLabel"}>Este estabelecimento ainda não possui avaliações.</label></div>;
        else return data.map((item, index) => renderCardEval(item, index));
    };

    return (
        <div className={"EvaluationItemListContainer"}>
            <Grid container style={{padding: 16}}>
                <Grid item lg={6} sm={6} xs={6} style={{display: 'flex', alignItems: 'center'}}>
                    <h5 style={{margin: 0}}>Avaliações</h5>
                </Grid>
                <Grid item lg={6} sm={6} xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={() => history.push(`/avaliar/${placeId}`)} variant='contained' color='primary'>
                        Avaliar
                    </Button>
                </Grid>
            </Grid>
            <div>
                {renderList()}
            </div>
        </div>
    );
};

const PlaceDetail = () => {
    const [,,isMobile] = useWindowSize();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const burgerState = location.state ? location.state.burger : null;
    const paramId = params.id;

    const item = useMemo(() => {
        const dashboard = JSON.parse(localStorage.getItem('dashboard'));
        if (dashboard) {
            let item = dashboard.find(f => f.id === paramId);
            if (!item) {
                item = burgerState;
            }
            return item;
        }
        return null;
    }, [burgerState, paramId]);

    useEffect(() => {
        window.scrollTo(0,0);
        SideBar.doGoBack(true, item.place);
    }, [item])

    const displayImage = getPlacesImages(item.place);

    return (
        <div className={"PlaceContainer"}>
            {!isMobile
                ? <div className={"PlaceHeader"}>
                    <IconButton onClick={() => history.goBack()}>
                        <ChevronLeft style={{width: 45, height: 45, marginRight: 10}} />
                    </IconButton>
                    <h1 className={"PlaceTitle"}>{item.place.name}</h1>
                    {item.certified ? <img src={CertifiedDark} alt={"certified dark"} style={{width: 30, marginRight: 10, marginLeft: 10}} /> : null}
                </div> : null}
            <div className={"PlaceContent"}>
                <Grid container>
                    <Grid item lg={7} sm={7} xs={12} style={{borderRight: "1px solid #00000012"}}>
                        <div className={"ImageContainer"}>
                            <img src={displayImage} alt={item.place.name} className={"BackgroundImage"} />
                        </div>
                        <div className={"EvaluationContainer"}>
                            <Grid container>
                                <DetailRating label={"Sabor"} name={'taste'} rating={item.taste} />
                                <DetailRating label={"Ambiente"} name={'ambiance'} rating={item.ambiance} />
                                <DetailRating label={"Serviço"} name={'service'} rating={item.service} />
                                <DetailRating label={"Carne"} name={'meat'} rating={item.meat} />
                                <DetailRating price label={"Preço"} name={'price'} rating={item.price} />
                                <DetailRating label={"Google Maps"} name={'google'} rating={item.place.rating} />
                            </Grid>
                        </div>
                        
                        <DetailAttendance place={item.place} social={item.social} hamburger={item} />
                        <DetailLocation place={item.place} />
                        <DetailGallery place={item.place} />
                        {isMobile ? <DetailEvaluation placeId={item.id} place={item.place}/> : null}
                    </Grid>
                    {!isMobile
                        ? <Grid item lg={5} sm={5} xs={12}>
                            <DetailEvaluation placeId={item.id} place={item.place} />
                        </Grid> : null}
                </Grid>
            </div>
        </div>
    );
};

export default PlaceDetail;
