import React from 'react';
import { Button, Typography } from '@material-ui/core';

import ErrorIcon from './errorIcon';
import './styles.css';

const CrashView = ({resetErrorBoundary}) => (
    <div className='crash-view centered-container'>
        <div>
            <ErrorIcon />
        </div>
        <Typography variant='h5' component='h1' className='crash-view title' gutterBottom>
            Tivemos um problema.
        </Typography>
        <Typography variant='subtitle2' className='crash-view subtitle' gutterBottom>
            Não se preocupe, a equipe de desenvolvimento já está cuidando disso.
        </Typography>
        <div>
            <Button variant='contained' color='secondary' onClick={() => resetErrorBoundary()}>
                Voltar ao início
            </Button>
        </div>
    </div>
);

export default CrashView;
