import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AUTH_LOGOUT, AUTH_LOGIN_SUCCESS } from '../redux/actions/types';
import { onLoginSuccess } from '../redux/actions/authActions';
import { withRouter,  } from 'react-router-dom';

class AccountMenu extends Component {
    handleClick = () => {
        this.props.history.push('/reviews');
    }
    componentDidMount() {
        this.props.isUserAuthenticated();
    }
    renderLoggedIn() {
        return (
            <div />
        )
    }
    render() {
        return this.props.user ? this.renderLoggedIn() : null
    }
}
const mapStateToProps = (state) => ({
    user: state.auth.user
})
const mapActions = (dispatch) => ({
    logout: () => {
        localStorage.clear();
        dispatch({ type: AUTH_LOGOUT });
    },
    onLoginSucces: (res) => dispatch(onLoginSuccess(res)),
    isUserAuthenticated: (success, fail) => {
        let user = JSON.parse(localStorage.getItem('auth'));
        if (user) {
            dispatch({
                type: AUTH_LOGIN_SUCCESS,
                payload: user
            })
        } else {
            console.log(fail);
        }
    }
})
export default withRouter(connect(mapStateToProps, mapActions)(AccountMenu));
