export default (async () => {
    if (!window.cordova) {
        const firebase = await import('firebase/app');
        await import('firebase/messaging');

        if (!firebase.apps || !firebase.apps.length) {
            firebase.initializeApp({
                apiKey: "AIzaSyAoT_E-gwz79eKCNCWjpz7TsM1E_YzvoJc",
                authDomain: "good-burger.firebaseapp.com",
                databaseURL: "https://good-burger.firebaseio.com",
                projectId: "good-burger",
                storageBucket: "good-burger.appspot.com",
                messagingSenderId: "493080137451",
                appId: "1:493080137451:web:5eda60ab51b74915f778ae"
            });
        }

        const messaging = firebase.messaging();
        return { messaging };
    }
    return { messaging: {
        requestPermission: () => {},
        getToken: () => {},
        onTokenRefresh: () => {},
        onMessage: () => {}
    } }
})();
