import React from 'react';
import './styles.css';

const CardBody = ({children}) => {
  return (
    <div className={"ItemContent"}>
      {children}
    </div>
  )
}

export {CardBody};