import ReduxThunk from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import listen from 'redux-action-listeners';
import {ActionEmitter} from '@app-masters/redux-lib';
import {AMStorage} from '@app-masters/js-lib';

import reducers from './reducers';

let listener = new ActionEmitter();
let storage = new AMStorage(window.localStorage);

const store = createStore(
    reducers,
    {},
    applyMiddleware(
        ReduxThunk,
        listen(listener)
    )
);
export {store, listener, storage};
