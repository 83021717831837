import React, {useState} from 'react';
import { Http, Rollbar } from '@app-masters/js-lib';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';

import Snackbar from '../../components/snackbar';

import './styles.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpertMenu = ({ hamburger }) => {
    const [social, setSocial] = useState(hamburger.social);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useSelector(store => store.auth.user);
    const isExpert = user && user.user && (user.user.role === 'admin' || user.user.data.expert);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        if(!loading) {
            setOpen(true);
        }
    }

    const handleChange = (evt) => {
        if (!!evt && !!evt.target) {
            setSocial({...social, [`${evt.target.name}`]: evt.target.value});
        }
    };

    const handleSubmit = async (evt) => {
        if (!!evt) {
            evt.preventDefault();
        }
        setLoading(true);
        try {
            await Http.put(`/hamburger/${hamburger._id}`, { social });
            handleClose();
        } catch (error) {
            Rollbar.error('Failed save social data', error);
            Snackbar.show({ message: 'Erro salvar dados', severity: 'error' });
        }
        setLoading(false);
    }

    return (
        isExpert ? (
        <div>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Editar dados
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className="expert-menu appbar">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="fechar">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className="expert-menu title">
                    {hamburger.place.name}
                    </Typography>
                    <Button autoFocus disabled={loading} color="inherit" onClick={handleSubmit}>
                        Salvar
                    </Button>
                </Toolbar>
                </AppBar>
                {loading ? <LinearProgress color="secondary" /> : null}
                <form className="expert-menu" onSubmit={handleSubmit} noValidate autoComplete="off">
                    <TextField fullWidth name="instagram" value={social.instagram || ''} onChange={handleChange} label="Instagram" />
                    <TextField fullWidth name="facebook" value={social.facebook || ''} onChange={handleChange} label="Facebook" />
                    <TextField fullWidth name="iFood" value={social.iFood || ''} onChange={handleChange} label="iFood" />
                    <TextField fullWidth name="whatsapp" value={social.whatsapp || ''} onChange={handleChange} label="Whatsapp" />
                    <TextField fullWidth name="orderOnline" value={social.orderOnline || ''} onChange={handleChange} label="Compra online" />
                </form>
            </Dialog>
        </div>
        ) : null
    );
}

export default ExpertMenu;