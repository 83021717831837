import React from 'react';
import Close from '@material-ui/icons/Close';
import { Modal, Slide } from '@material-ui/core';
import { IconButton } from 'material-ui';
import './styles.css';

const ModalUp = ({children, title, open, extra, closeModal}) => {
	return (
		<Modal
			open={open}
			style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} 
			onClose={closeModal}
			>
			<Slide direction="up" in={open} mountOnEnter unmountOnExit>
				<div className={"ModalFilter"}>
					<div className={"ModalHeader"}>
						<IconButton className={"MobileIcon"} onClick={closeModal}>
							<Close color='inherit' />
						</IconButton>
						<h6>{title}</h6>
						{extra}
					</div>
					{children}
				</div>
			</Slide>
		</Modal>
	)
}

export default ModalUp;