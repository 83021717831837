import {
    AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAIL, AUTH_LOGOUT, AUTH_INIT
} from '../actions/types';

const INITIAL_STATE = {
    user: null
};

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
        return {...state, user: action.payload , loading:false};
    case AUTH_LOGIN_FAIL:
        return {...state, error: action.payload};
    case AUTH_LOGOUT:
        return {...state, user: null};
    case AUTH_INIT:
        return {...state, loading:true};
    default:
        return state;
    }
};
