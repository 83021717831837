import React, {useEffect} from 'react';
import { Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

export const SnackbarItem = ({
    id,
    message,
    dismissible,
    autoHideDuration,
    dismiss,
    severity,
    ...props
}) => {
    // Set a timer to the message be displayed
    useEffect(() => {
        if (!dismissible) {
            const timer = setTimeout(() => {
                dismiss(id || '');
            }, autoHideDuration || 5000);
            return () => clearTimeout(timer);
        }
    }, [id, dismissible, autoHideDuration, dismiss]);

    return (
        <Snackbar open onClose={() => dismiss(id || '')} message={severity ? undefined : message} {...props} >
            {severity
                ? (<Alert onClose={() => dismiss(id || '')} severity={severity}>
                    <Typography>{message}</Typography>
                </Alert>)
                : undefined
            }
        </Snackbar>
    );
};
