import { AUTH_LOGIN_SUCCESS, AUTH_LOGOUT, AUTH_LOGIN_FAIL, AUTH_INIT } from './types';
import { Http, Rollbar } from '@app-masters/js-lib';
import { messaging } from '../../firebase';

export const onLoginSuccess = (userData, name) => dispatch => {
    Http.setHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'client': 'admin',
        'Authorization': userData.token
    });
    Http.setEndpointParam('{_id}', userData.user._id);
    if (name) {
        doUpdateName(userData, name)(dispatch);
    } else {
        dispatch({type: AUTH_LOGIN_SUCCESS, payload: userData});
    }
    return userData;
};

export const doUpdateName = (userData, name) => dispatch => {
    Http.put(`/user/${userData.user._id}`, {name})
        .then(r => {
            console.log(r);
            dispatch({type: AUTH_LOGIN_SUCCESS, payload: {...userData, user: r}});
        })
        .catch(r => {
            localStorage.clear();
            Rollbar.error('Error login in', r);
            dispatch({type: AUTH_LOGIN_FAIL, payload: r});
        });
};

export const doLogin = ({email, password, name}) => dispatch => {
    dispatch({type: AUTH_INIT});
    Http.post('/login', {email, password})
        .then(r => {
            localStorage.setItem('auth', JSON.stringify(r));
            return onLoginSuccess(r, name)(dispatch);
        }).then(async () => {
            const value = await messaging.listenFCM();
            if (!value) return true;
            const type = (window.cordova && window.cordova.platformId) || 'web';
            return Http.post('/notifications/add_token', {type, value});
        })
        .catch(r => {
            localStorage.clear();
            Rollbar.error('Error login in', r);
            dispatch({type: AUTH_LOGIN_FAIL, payload: r});
        });
};

export const validateEmail = (email) => {
    return (dispatch) => {
        var re = /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
            dispatch({type: AUTH_LOGIN_FAIL, payload: 'Email Invalido'});
            return false;
        } else {
            dispatch({type: AUTH_LOGIN_FAIL, payload: undefined});
            return true;
        }
    };
};

export const validateName = (name) => {
    return (dispatch) => {
        if (!name || (name && name.lenght < 3)) {
            dispatch({type: AUTH_LOGIN_FAIL, payload: 'Nome Inválido'});
            return false;
        } else {
            dispatch({type: AUTH_LOGIN_FAIL, payload: undefined});
            return true;
        }
    };
};

export const doLogOut = (props) => (dispatch) => {
    localStorage.setItem('auth', null);
    dispatch({ type: AUTH_LOGOUT });
};
