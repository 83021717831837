import React from 'react';

export default () => (
    <div>
        <svg xmlns='http://www.w3.org/2000/svg' style={{ width: 180 }} viewBox='0 0 512 512'>
            <path fill='#f4c064' d='M380.4 381.1h11.1c8.2 0 14.8 6.6 14.8 14.8 0 16.3-13.3 29.6-29.6 29.6H139.9c-16.3 0-29.6-13.3-29.6-29.6 0-8.2 6.6-14.8 14.8-14.8H380.4z' />
            <path fill='#ffe954' d='M256.5 421c-9.6 0-18.8-1.8-26.2-5.1l-77.4-34.7h207.2l-77.4 34.7c-7.4 3.3-16.7 5.1-26.2 5.1z' />
            <path fill='#91f18b' d='M404.5 374.6c0 16.3-13.3 29.6-29.6 29.6-9.7 0-18.3-4.7-23.7-11.9a7.25 7.25 0 00-5.9-2.9c-2.3 0-4.5 1.1-5.9 2.9-5.4 7.2-14 11.9-23.7 11.9s-18.3-4.7-23.7-11.9a7.25 7.25 0 00-5.9-2.9c-2.3 0-4.5 1.1-5.9 2.9-5.4 7.2-14 11.9-23.7 11.9s-18.3-4.7-23.7-11.9a7.25 7.25 0 00-5.9-2.9c-2.3 0-4.5 1.1-5.9 2.9-5.4 7.2-14 11.9-23.7 11.9s-18.3-4.7-23.7-11.9a7.25 7.25 0 00-5.9-2.9c-2.3 0-4.5 1.1-5.9 2.9-5.4 7.2-14 11.9-23.7 11.9-16.3 0-29.6-13.3-29.6-29.6 0-28.6 23.2-51.8 51.8-51.8h192.4c28.6 0 51.8 23.1 51.8 51.8z' />
            <path fill='#ff8355' d='M404.5 337.6c0 8.2-6.6 14.8-14.8 14.8H123.3c-8.2 0-14.8-6.6-14.8-14.8 0-8.2 6.6-14.8 14.8-14.8h266.4c8.2 0 14.8 6.6 14.8 14.8z' />
            <path fill='#99774f' d='M404.5 297.6a25.9 25.9 0 01-25.9 25.9H134.4a25.9 25.9 0 010-51.8h244.2a25.9 25.9 0 0125.9 25.9z' />
            <g>
                <path fill='#f4c064' d='M421.6 411.2L269 192.8c-4.7-6.7-3-15.9 3.7-20.6 46.9-32.8 111.5-21.3 144.3 25.6l50.9 72.8c32.8 46.9 21.3 111.5-25.6 144.3-6.8 4.6-16 3-20.7-3.7z' />
                <path fill='#ffee92' d='M436.3 380.6c-2.5 1.8-6 1.1-7.7-1.4l-4.2-6.1c-1.7-2.5-1.1-6 1.5-7.7 2.5-1.7 5.9-1.1 7.6 1.3l4.2 6.1c1.7 2.5 1.1 6-1.4 7.8zm26.9-61.7l-4.2-6.1a5.7 5.7 0 00-7.8-1.3c-2.4 1.8-3 5.1-1.3 7.6l4.2 6.1c1.8 2.5 5.3 3 7.8 1.3 2.4-1.8 3-5.1 1.3-7.6zm-6.9 38.6a5.6 5.6 0 001.4-7.7l-4.2-6.1a5.7 5.7 0 00-7.8-1.3c-2.4 1.8-3 5.1-1.3 7.6l4.2 6.1c1.8 2.6 5.2 3.2 7.7 1.4zm-31.9-29.6l-4.2-6.1a5.7 5.7 0 00-7.8-1.3c-2.4 1.8-3 5.1-1.3 7.6l4.2 6.1c1.8 2.5 5.3 3 7.8 1.3 2.4-1.7 2.9-5.1 1.3-7.6z' />
            </g>
            <g>
                <path fill='#cea05d' d='M134.3 432.6H51.1v-11.1h83.3c3.1 0 5.5 2.6 5.5 5.6-.2 3-2.6 5.4-5.6 5.5z' />
                <path fill='#ff8355' d='M95.5 438.8V427h37v11.8c0 4.1-3.3 7.4-7.4 7.4h-22.2c-4.1 0-7.4-3.3-7.4-7.4z' />
                <path fill='#f49744' d='M51.1 451.3a5.6 5.6 0 01-5.6-5.6v-37c0-3.1 2.6-5.5 5.6-5.5 3 0 5.4 2.5 5.5 5.5v37c0 3.2-2.5 5.6-5.5 5.6z' />
            </g>
            <g className='error-icon fly'>
                <ellipse fill='#c1c1c1' transform='rotate(-37 262.77 89.72)' cx='262.8' cy='89.7' rx='6.1' ry='9.6' />
                <ellipse fill='#51504f' cx='270.3' cy='99.8' rx='13.2' ry='7.5' />
                <ellipse fill='#e2e2e2' transform='rotate(-37 267.69 88.37)' cx='267.7' cy='88.4' rx='6.1' ry='9.6' />
            </g>
            <path d='M241.1 103.7c-27.3 1.2-28.8 8-21.2 12.1 18.2 9.7 81 2.8 60 10.4-21 7.5-34.2 18.9-34.6 27.3' fill='none' stroke='#e2e2e2' strokeMiterlimit='10' />
        </svg>

    </div>
);
