import React, { useEffect } from 'react';
import './styles.css';
import GoodBurgerPeople from '../../assets/goodBurgerPeople.jpg';

import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { Grid, CardHeader, CardContent } from '@material-ui/core';
import SideBar from '../../components/sideBar';
import AppMastersLogo from '../../components/appMastersLogo';

import brunoAvatarImage from '../../assets/avatar_bruno.jpg'
import julioAvatarImage from '../../assets/avatar_juilio.jpg'

const About = () => {
    useEffect(() => {
        window.scrollTo(0,0);
        SideBar.doGoBack(false);
        SideBar.toggleSearch(false, null);
    }, []);

    return (
        <div className={"AboutContainer"}>
            <h1>Good Burger</h1>
            <h2>O app tem como objetivo permitir compartilhar avaliações de hamburgerias entre os usuários, sempre levando em consideração as avaliações apenas dos últimos dias, permitindo assim uma lista sempre atualizada das melhores opções em hamburger na cidade.</h2>
            <img src={GoodBurgerPeople} alt='Goodburguer' className={"GoodImage"} />
            <h2>O nome faz referencia ao filme A Guerra do Hambúrger, de 1997, estrelado por Kell Mitchell e Kenan Thompson nos papeis de atendentes da lanchonete Good Burger.</h2>
            <h2>Este aplicativo foi criado na App Masters durante a realização de um processo de seletivo e foi desenvolvido por:</h2>
            <Grid container spacing={2}>
                <Grid item lg={6} sm={6} xs={12} style={{minWidth: 350}}>
                    <Card className={"CardAvatar"}>
                        <CardHeader
                            style={{flex: 1}}
                            avatar={
                                <Avatar alt="Bruno Cangussu" src={brunoAvatarImage} />
                            }
                            title='Bruno Cangussu'
                            subheader='bruno.cangussu@hotmail.com'
                        />
                        <CardContent style={{display: "flex", flexDirection: 'column'}}>
                            <a rel='noopener noreferrer' target='_blank' href='https://www.facebook.com/brunofcangussu'><i className='AboutIcon fab fa-facebook-square' /></a>
                            <a rel='noopener noreferrer' target='_blank' href='https://github.com/brunocangs'><i className='AboutIcon fab fa-github' /></a>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={6} sm={6} xs={12} style={{minWidth: 350}}>
                    <Card className={"CardAvatar"}>
                        <CardHeader
                            style={{flex: 1}}
                            avatar={
                                <Avatar alt="Júlio Piubello" src={julioAvatarImage} />
                            }
                            title='Júlio Piubello'
                            subheader='juliopiubellow@gmail.com'
                        />
                        <CardContent style={{display: "flex", flexDirection: 'column'}}>
                            <a rel='noopener noreferrer' target='_blank' href='https://www.facebook.com/julio.piubello'><i className='AboutIcon fab fa-facebook-square' /></a>
                            <a rel='noopener noreferrer' target='_blank' href='https://github.com/juliopiubello'><i className='AboutIcon fab fa-github' /></a>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div className={"Footer"}>
                <a rel='noopener noreferrer' target='_blank' href='https://appmasters.io/'>
                    <AppMastersLogo />
                </a>
            </div>
        </div>
    );
};

export default About;
