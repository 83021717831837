import firebase from './firebase';

const requestPermission = async () => {
    return new Promise((resolve, reject) => {
        firebase
            .then(({ messaging }) => resolve(messaging.requestPermission()))
            .catch(reject);
    })
}

const getToken = async () => {
    return new Promise((resolve, reject) => {
        firebase
            .then(({ messaging }) => messaging.getToken().then(token => resolve(token)))
            .catch(reject);
    })
};

const onMessage = async (cb) => {
    return new Promise((resolve, reject) => {
        firebase
            .then(({ messaging }) => resolve(messaging.onMessage(cb)))
            .catch(reject);
    })
}

const onTokenRefresh = async (cb) => {
    return new Promise((resolve, reject) => {
        firebase
            .then(({ messaging }) => resolve(messaging.onTokenRefresh(cb)))
            .catch(reject);
    })
}

if (window.cordova && window.FirebasePlugin) {
    if (window.FirebasePlugin) {
        window.FirebasePlugin.onTokenRefresh(window.FirebasePlugin.getToken)
    } else {
        console.log('FirebasePlugin is not present')
    }
} else {
    onTokenRefresh(getToken)
}

const setupMessageListener = () => {
    if (window.cordova) {
        if (window.FirebasePlugin) {
            window.FirebasePlugin.onMessageReceived(console.log);
        } else {
            console.log('FirebasePlugin is not present')
        }
    }
    onMessage(console.log);
}
setTimeout(setupMessageListener, 1000);

export const listenFCM = async () => {
    try {
        if (window.cordova) {
            if (window.cordova.platformId === 'ios') {
                await window.FirebasePlugin.grantPermission();
            }
            return new Promise((resolve) => window.FirebasePlugin.getToken(token => {
                console.log('FCM Token:', token);
                resolve(token)
            }))
        }
        await requestPermission();
        const token = await getToken();
        console.log('FCM Token:', token);
        return token;
    } catch (error) {
        console.log('ERR', error)
    }
}
