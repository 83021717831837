import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthRoute from './authRoute';

import HamburgerList from '../views/home';
import About from '../views/about';
import PlaceDetail from '../views/place/detail';
import PlaceEvaluate from '../views/place/evaluate';
import PlaceForm from '../views/place/form';
import Login from '../views/login';
import Logout from '../views/logout';
import UserReviews from '../views/reviews';

export default () => (
    <Switch>
        <Route exact path='/' component={HamburgerList} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/about' component={About} />
        <Route exact path='/place/:id' component={PlaceDetail} />
        <AuthRoute exact path='/login' component={Login} />
        <AuthRoute exact path='/avaliar/:id' component={PlaceEvaluate} />
        <AuthRoute exact path='/hamburger/new' component={PlaceForm} />
        <AuthRoute exact path='/reviews' component={UserReviews} />
        <Route path='*' render={() => (<Redirect to='/' />)} />
    </Switch>
);
