// Auth
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_INIT = 'AUTH_INIT';

//First time
export const FIRST_TIME_CHANGED = 'FIRST_TIME_CHANGED';
export const SEARCH_BAR_TOGGLED = 'SEARCH_BAR_TOGGLED';

//
export const TITLE_CHANGED = 'TITLE_CHANGED';
export const TITLE_RESET = 'TITLE_RESET';

//Location
export const CURRENT_LOCATION = 'CURRENT_LOCATION';