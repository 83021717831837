import {SEARCH_BAR_TOGGLED} from '../actions/types';
const INITIAL_STATE = false
export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        case SEARCH_BAR_TOGGLED:
            return !state
        default:
            return state;
    }
}