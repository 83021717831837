import React from 'react';

export default (props) => (
    <svg
      width={20}
      height={20}
      {...props}
      viewBox="0 0 193.469 193.469"
    >
      <path fill={props.color} d="M192.216 54.186a3.117 3.117 0 00-3.678-.043l-50.181 35.752a3.107 3.107 0 00-1.097 3.666c.481 1.236.719 2.479.719 3.702 0 5.59-4.542 10.133-10.132 10.133-5.584 0-10.133-4.543-10.133-10.133 0-5.346 4.214-9.767 9.585-10.059a3.116 3.116 0 002.643-4.448l-30.4-64.092c-1.035-2.189-4.604-2.189-5.639 0L63.482 82.808a3.15 3.15 0 00.119 2.886c.505.893 1.416 1.458 2.433 1.559 5.261.459 9.222 4.774 9.222 10.01 0 5.59-4.545 10.133-10.132 10.133s-10.132-4.543-10.132-10.133c0-.992.192-2.058.59-3.245.43-1.291-.012-2.713-1.108-3.504L4.962 54.167a3.115 3.115 0 00-3.696.006A3.13 3.13 0 00.17 57.705l30.79 88.637c11.091-9.152 45.796-11.807 65.775-11.807 19.975 0 54.671 2.654 65.771 11.807l30.787-88.637a3.104 3.104 0 00-1.077-3.519zm-92.649-3.764L86.533 77.881c4.813 5.289 7.447 12.084 7.447 19.37a3.121 3.121 0 01-3.118 3.117 3.12 3.12 0 01-3.118-3.117c0-6.345-2.521-12.182-7.097-16.465a3.112 3.112 0 01-.685-3.602l13.959-29.45c.743-1.559 2.627-2.208 4.152-1.48a3.14 3.14 0 011.494 4.168zm60.316 102.252c0-3.556-21.477-11.886-63.13-11.886-41.659 0-63.138 8.33-63.138 11.886 0 .293-.094.549-.162.805l2.487 7.16c.024.067.095.098.119.152 1.096 12.337 39.96 15.655 60.693 15.655 20.73 0 59.586-3.307 60.682-15.655.024-.061.098-.104.122-.152l2.484-7.16c-.072-.256-.157-.512-.157-.805zm-63.13 17.537c-34.193 0-53.983-6.57-54.565-9.932.542-3.373 20.301-9.968 54.565-9.968 34.267 0 54.014 6.595 54.556 9.944-.578 3.386-20.368 9.956-54.556 9.956z" />
    </svg>
);
