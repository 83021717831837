import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from './authReducer';
import firstTimeReducer from './firstTimeReducer';
import searchBarReducer from './searchBarReducer';
import appBarReducer from './appBarReducer';
import locationReducer from './locationReducer';

export default combineReducers({
    auth: authReducer,
    router: routerReducer,
    firstTime: firstTimeReducer,
    searchBar: searchBarReducer,
    appBar: appBarReducer,
    location: locationReducer
});