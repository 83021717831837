import { useLayoutEffect, useState } from 'react';
import moment from 'moment';
import {  AppBootstrap } from '@app-masters/js-lib';

export const DatesFromPlace = (place) => {
    const weekdayText = place.opening_hours ? place.opening_hours.weekday_text : null;
    const dateList = [];
    if (!weekdayText) return [];
    weekdayText.forEach(dayString => {
        const [day, time] = dayString.split(":");
        if (!time.includes("Closed") && !time.includes("Fechado")) {
            dateList.push(day);
        }
    });
    const firstDay = dateList[0].replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    const lastDay = dateList[dateList.length - 1].replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));

    const time = weekdayText[moment().day() - 1].replace(weekdayText[moment().day() - 1].split(":")[0], "").replace(':', "").trim();

    return [firstDay, lastDay, time, dateList];
};

export const getPlacesImages = (place) => {
  const API_KEY = AppBootstrap.getConfig().mapsToken;
  try {
    const photo = place && place.photos && place.photos.length > 0 ? place.photos[0].photo_reference  : undefined;
    return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=300&photoreference=${photo}&key=${API_KEY}`;
  } catch (err) {
    return require('../assets/burger.png');
  }
}

let windowSize = [
  typeof window !== 'undefined' ? window.innerWidth : 0,
  typeof window !== 'undefined' ? window.innerHeight : 0,
  typeof window !== 'undefined' ? window.innerWidth < 600 : true,
];

export const getWindowSize = () => {
  return windowSize;
}

export const isMobile = () => {
  return windowSize[2];
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0, false]);

  useLayoutEffect(() => {
    const updateSize = () => {
      windowSize = [window.innerWidth, window.innerHeight, window.innerWidth < 600]
      setSize(windowSize);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}