/*
 * Generate a random ID
 */
export const makeID = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const formatUrl = (url) => {
    if (url.startsWith('http')) {
        return url;
    } else if (url.startsWith('www.')) {
        return `http://${url}`;
    } else {
        return `http://www.${url}`;
    }
};

export const whatsappMessageUrl = (number, message) => {
    const strNumber = String(number).replace(/\D/g, '');
    const normalizedNumber = strNumber.startsWith('55') ? strNumber : `55${strNumber}`;
    const encodedMessage = message ? `?text=${encodeURI(message)}` : undefined;

    return `https://wa.me/${normalizedNumber}${encodedMessage}`;
};
