import React, { useState } from 'react';
import Crow from '../../assets/crow';
import Certified from '../../assets/certified.png';
import Rating from '@material-ui/lab/Rating';
import './styles.css';
import { Grid } from '@material-ui/core';

const CardHeader = ({image, certified, rank, imageAlt}) => {
  const [errorImage, setImage] = useState(null);
  return (
    <>
        {Number(rank) > -1 ? <div className={"EvaluationChip"}>
            {rank < 3  ? 
              <Crow style={{marginRight: 5}} color={rank === 0 ? '#ffce0a' : rank === 1 ? '#bdc3c7' : '#f2902e'} />
            : null}
            {`${rank + 1}º`}
        </div> : null}
        <div className={"ItemImage"}>
            <img src={errorImage || image} alt={imageAlt} onError={() => setImage(require("../../assets/burger.png"))} />
            {certified ? 
                <div className={"CertifiedContainer"}>
                    <img src={Certified} alt="certified" style={{width: 18, height: 18}} />
                </div> : null}
        </div>
    </>
  )
}

const CardInfo = ({name, rating}) => {
  return (
    <Grid container style={{alignItems: 'center', justifyContent: 'space-between'}}>
      <Grid item sm={8} lg={8} xs={8} style={{overflow: "hidden", textOverflow: 'ellipsis'}}>
        <label className={"ItemName"}>{name}</label>
      </Grid>
        {Number(rating) > -1 ? 
            <Rating
              name={name}
              value={rating}
              disabled
            /> : null }
    </Grid>
  )
}

export {CardHeader, CardInfo};