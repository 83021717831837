import React, { useState, useCallback } from 'react';

import { SnackbarItem } from './item';
import { makeID } from '../../utils/string';

const initialState = {
    // The functions will be overridden in the SnackbarProvider down below.
    hasProvider: false,
    messageList: [],
    show: async () => {
        throw new Error('No SnackbarProvider was found');
    },
    dismiss: () => {
        throw new Error('No SnackbarProvider was found');
    }
};

let globalState = initialState;

/**
 * The Snackbar provider for its react context
 */
export const SnackbarProvider = () => {
    // State used to track the list of messages
    const [messageList, setMessageList] = useState(initialState.messageList);

    const show = useCallback((notification) => {
        const notify = {...notification, id: makeID(11)};
        setMessageList((state) => [...state, notify]);
    }, []);

    const dismiss = useCallback((id) => {
        setMessageList((state) => state.filter((f) => f.id !== id));
    }, []);

    globalState = {
        hasProvider: true,
        messageList,
        show,
        dismiss
    };

    return (
        messageList && messageList.length > 0 ? (<SnackbarItem key={messageList[0].id} dismiss={dismiss} {...(messageList[0])} />) : null
    );
};

/**
 * Return the global state without using context
 */
export const getSnackbar = () => globalState;

export default {
    show: (notification) => globalState.show(notification),
    dismiss: (id) => globalState.dismiss(id)
};
