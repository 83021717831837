import {createMuiTheme, fade} from '@material-ui/core/styles';

import Cyan from '@material-ui/core/colors/cyan';
import Pink from '@material-ui/core/colors/pink';
import Grey from '@material-ui/core/colors/grey';

const red = "#B71C1C";
const white = "#FFFFFF";
const darkBlack = "#333333";
const fullBlack = "#000000";

export default createMuiTheme({
    fontFamily: 'Roboto, sans-serif',
    palette: {
        primary: {
            main: red
        },
        secondary: {
            main: "#00AF5E"
        },
        primary2Color: Cyan[700],
        primary3Color: Grey[400],
        accent1Color: Pink[200],
        accent2Color: Grey[100],
        accent3Color: Grey[500],
        textColor: darkBlack,
        alternateTextColor: white,
        canvasColor: white,
        borderColor: Grey[300],
        disabledColor: fade(darkBlack, 0.3),
        pickerHeaderColor: Cyan[500],
        clockCircleColor: fade(darkBlack, 0.07),
        shadowColor: fullBlack
    },
    toolbar: {
        backgroundColor: red
    },
    raisedButton: {
        secondaryColor: '#e00000'
    }
});
