import React from 'react';
import { Rollbar } from '@app-masters/js-lib';

import CrashView from '../../views/crashView';

const defaultErrorHandler = (error, componentStack) => {
    Rollbar.critical(error, {componentStack});
};

const DefaultErrorFallback = (props) => {
    return <CrashView {...props} />;
};

const defaultResetHandler = () => {
    window.location.href = "/";
};

export const ErrorHandler = ({children}) => (
    <ErrorBoundary fallback={DefaultErrorFallback} onError={defaultErrorHandler} onReset={defaultResetHandler}>
        {children}
    </ErrorBoundary>
);

const initialState = {
    error: undefined,
    info: undefined
};

export class ErrorBoundary extends React.Component {
    constructor (props) {
        super(props);
        this.state = initialState;
        this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    }

    resetErrorBoundary (...args) {
        if (this.props.onReset) {
            this.props.onReset(...args);
        }
        this.setState(initialState);
    };

    /**
     * Catch unhandled render error in the children elements
     * @param error Error message
     * @param info Error info containing the compnent stack
     */
    componentDidCatch (error, info) {
        if (this.props.onError) {
            this.props.onError(error, info ? info.componentStack : undefined);
        }
        this.setState({error, info});
    }

    render () {
        const {error, info} = this.state;
        const {fallback} = this.props;

        if (error) {
            const props = {
                componentStack: info ? info.componentStack : undefined,
                error,
                resetErrorBoundary: this.resetErrorBoundary
            };
            if (fallback) {
                return fallback(props);
            } else {
                throw new Error('Error Boundary requires either a fallback or fallbackRender prop');
            }
        }

        return this.props.children;
    }
}

export default ErrorHandler;
