import React, { useEffect, useState, useCallback } from 'react';
import SideBar from '../../components/sideBar';
import Snackbar from '../../components/snackbar';
import Loading from '../../components/loading';
import { Http, Rollbar } from '@app-masters/js-lib';
import { useSelector } from 'react-redux';
import { Grid, Divider } from '@material-ui/core';
import { useWindowSize } from '../../utils/constants';
import InfiniteScroll from 'react-infinite-scroller';
import ReviewItem from '../../components/reviewItem';
import ModalUp from '../../components/modalUp';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import './styles.css'
import { useHistory } from 'react-router-dom';

const UserReviews = () => {
    const [,, isMobile] = useWindowSize();
    const [modalOpen, setModalOpen] = useState({show: false, item: null});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const user = useSelector(store => store.auth.user);

    const history = useHistory();

    const getData = useCallback(async () => {
        setLoading(true);
        Http.get(`/rating?user=${user.user._id}&populate=hamburger&sort=-average`)
            .then(data => {
                const list = data.filter(f => f.hamburger);
                setData(list);
                setFilteredData(list.slice(0, 8));
                setLoading(false);
            }).catch(e => {
                Rollbar.error('Failed to load user reviews', e);
                Snackbar.show({ message: 'Erro ao carregar avaliações', severity: 'error' });
            });
    }, [user]);

    useEffect(() => {
        window.scrollTo(0,0);
        SideBar.doGoBack(false, { name: 'Minhas avaliações' });
        SideBar.toggleSearch(false, null);

        getData();
    }, [getData]);

    const parseType = (type) => {
        const types = [
            'fast-food',
            'podrao',
            'artesanal'
        ];
        const names = [
            'Fast Food',
            'Podrão',
            'Artesanal'
        ];
        return (
            names[types.indexOf(type)]
        );
    }
    
    const renderMoreData = () => {
        setFilteredData([...filteredData, ...data.slice(filteredData.length, filteredData.length + 8)])
    }

    const SectionItem = ({children, type}) => {
        return (
            <div className={"SectionItem"}>
                <label className={"SectionItemLabel"}>{type}</label>
                {children}
            </div>
        )
    }
    
    return (
       <div className={"ReviewsContainer"}>
            <div className={"ReviewsContent"}>
                {!isMobile ?
                    <Grid container className="ListHeader">
                        <Grid item sm={12} md={12} lg={12}>
                            <h1 className="ReviewsHeaderLabel">Minhas avaliações</h1>
                        </Grid>
                    </Grid>
                 : null}
                 {data.length === 0 && loading ? <Loading /> 
                 : <InfiniteScroll
                    pageStart={0}
                    loadMore={renderMoreData}
                    hasMore={data.length !== filteredData.length}
                    style={{
                        display: "flex",
                        flexWrap: "wrap"
                    }}
                >
                    {filteredData.map((item, index) => 
                        <div key={index} className={"ItemContainer"} onClick={() => history.push(`/place/${item.hamburger.id}`)}>
                            <ReviewItem item={item} />
                        </div>
                    )}
                </InfiniteScroll>}
            </div>
            <ModalUp open={modalOpen.show} closeModal={() => setModalOpen({show: false})} title={`Avaliação em ${modalOpen.item ? modalOpen.item.hamburger.place.name : ''}`}>
                {modalOpen.item ? 
                <div className={"ModalReview"}>
                    <div style={{display: 'flex'}}>
                        <SectionItem type={"Tipo"}>
                            {parseType(modalOpen.item.type)}
                        </SectionItem>
                        <SectionItem type={"Tamanho"}>
                            {modalOpen.item.size}
                        </SectionItem>
                        <SectionItem type={"Avalidado"}>
                            {moment(modalOpen.item.created_at).fromNow()}
                        </SectionItem>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SectionItem type={"Sabor"}>
                            <Rating
                                name={'taste'}
                                value={modalOpen.item.taste}
                                disabled
                                />
                        </SectionItem>
                        <SectionItem type={"Carne"}>
                            <Rating
                                name={'meat'}
                                value={modalOpen.item.meat}
                                disabled
                            />
                        </SectionItem>
                        <SectionItem type={"Preço"}>
                            <Rating
                                name={'price'}
                                value={modalOpen.item.price}
                                disabled
                            />
                        </SectionItem>
                    </div>
                    {modalOpen.item.place !== 'delivery' ?
                        <div>
                            <label>Local</label>
                            <Divider />
                            <div style={{display: 'flex', paddingTop: 8}}>
                                <SectionItem type={"Serviço"}>
                                    <Rating
                                        name={'service'}
                                        value={modalOpen.item.service}
                                        disabled
                                    />
                                </SectionItem>
                                <SectionItem type={"Ambiente"}>
                                    <Rating
                                        name={'ambiance'}
                                        value={modalOpen.item.ambiance}
                                        disabled
                                    />
                                </SectionItem>
                            </div>
                        </div> 
                    : 
                        <div>
                            <label>Delivery</label>
                            <Divider />
                            <div style={{display: 'flex', paddingTop: 8}}>
                                <SectionItem type={"Quente"}>
                                    <Rating
                                        name={'warm'}
                                        value={modalOpen.item.warm}
                                        disabled
                                    />
                                </SectionItem>
                                <SectionItem type={"Entrega"}>
                                    <Rating
                                        name={'time'}
                                        value={modalOpen.item.time}
                                        disabled
                                    />
                                </SectionItem>
                            </div>
                        </div>
                    }
                    <SectionItem type={"Comentário"}>
                        {modalOpen.item.notes}
                    </SectionItem>
                </div> : null }
            </ModalUp>
       </div>
    )
}

export default UserReviews;