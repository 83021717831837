import React, { Component } from "react";
import { connect } from 'react-redux';
import { Http } from '@app-masters/js-lib';
import { doLogin, validateEmail, validateName } from '../../redux/actions/authActions';
import Loading from '../../components/loading';
import Burguer from '../../assets/burger512.png';
import { Card, Button, TextField, Typography, Slide, Fade } from '@material-ui/core';

class Login extends Component {
    constructor (props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            askName: false,
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
    }
    async handleLogin () {
        let { email } = this.state;
        email = email.toLowerCase()

        console.log();

        if (this.props.validateEmail(email)) {
            const response = await Http.post('/requireName', { email });
            if (response.require) {
                this.setState({ askName: true })
            } else {
                this.props.doLogin({ email, password: email });
                if(this.props.match.path === '/login') {
                    this.props.history.replace('/');
                }
            }
        }
    }

    async handleSignup () {
        let { email, name } = this.state;
        email = email.toLowerCase()

        if (this.props.validateName(name)) {
            this.props.doLogin({ email, password: email, name });
        }
        if(this.props.match.path === '/login') {
            this.props.history.replace('/');
        }
    }

    componentDidMount () {
        window.addEventListener('keydown', this.handleKeyDown)
    }
    componentWillUnmount () {
        window.removeEventListener('keydown', this.handleKeyDown)

    }
    handleKeyDown (event) {
        if (event.key === 'Enter') {
            this.onButtonPress();
        }
    }
    handleInput = (name, value) => {
        let { state } = this;
        state[name] = value;
        this.setState(state);
    }
    renderContent = () => (
        <div>
            <div
                style={{
                    display: "flex",
                    paddingTop: "5%",
                    paddingBottom: "10%",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <img
                    src={Burguer}
                    style={{
                        width: '30%'
                    }}
                    alt={"logo"}
                />
            </div>

            <Typography variant="h6" component="p" align="center" style={{ fontWeight: 300, marginBottom: 32, height: 64 }} color="textSecondary">
                {!this.state.askName 
                    ? (
                        <>
                            Para utilizar por favor entre com o seu email<br />
                            {' '}
                        </>
                    ) : (
                        <>
                            Sua primeira vez por aqui?<br />
                            Por favor, insira seu nome
                        </>
                    )}
            </Typography>
            <Card style={styles.loginContainer}>
                <div style={{ height: 45, width: '100%', display: 'flex', position: 'relative' }}>
                    <Fade style={{ position: 'absolute', width: '100%' }} direction="right" in={!this.state.askName} mountOnEnter unmountOnExit>
                        <TextField
                            label='Email'
                            id='email'
                            fullWidth
                            autoFocus
                            value={this.state.email}
                            onChange={(e) => this.handleInput('email', e.target.value)}
                            error={!!this.props.error}
                            helperText={this.props.error}
                        />
                    </Fade>
                    <Slide style={{ position: 'relative' }} direction="left" in={this.state.askName} mountOnEnter unmountOnExit>
                        <TextField
                            style={{ position: 'absolute' }}
                            label='Nome'
                            id='name'
                            fullWidth
                            autoFocus
                            value={this.state.name}
                            onChange={(e) => this.handleInput('name', e.target.value)}
                            error={!!this.props.error}
                            helperText={this.props.error}
                        />
                    </Slide>
                </div>
                <div style={styles.containerButtons}>
                    <Button 
                        variant="contained" 
                        fullWidth 
                        color="primary" 
                        onClick={() => this.state.askName ? this.handleSignup () : this.handleLogin()}
                    >
                        Entrar
                    </Button>
                </div>
            </Card>
        </div>
    )
    render () {
        return (
            !this.props.loading ? this.renderContent() : <Loading />
        );
    }
}

const styles = {
    loginContainer: {
        padding: 20,
        minWidth: 80,
        maxWidth: 400,
        width: "90%",
        margin: "auto",
        backgroundColor: "#fff",
        overflow: 'hidden'
    },
    containerButtons: {
        display:'flex',
        flexDirection:'column',
        // height:110,
        justifyContent:'space-between',
        alignItems:'center',
        textAlign: "center",
        paddingTop: "40px"
    }
};
const mapStateToProps = ({ auth }) => ({
    error: auth.error,
    loading: auth.loading
})
export default connect(mapStateToProps, { doLogin, validateEmail, validateName })(Login);
