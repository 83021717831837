import { Component } from 'react';
import { connect } from 'react-redux';
import { AUTH_LOGOUT } from '../../redux/actions/types';

class Logout extends Component {
  componentDidMount () {
    this.props.logout();
    this.props.history.push('/');
  }

  render() {
    return null;
  }
}


const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapActions = (dispatch) => ({
  logout: () => {
      localStorage.clear();
      dispatch({ type: AUTH_LOGOUT });
  },
})

export default connect(mapStateToProps, mapActions)(Logout);
