import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Http, Rollbar } from '@app-masters/js-lib';
import { withRouter } from 'react-router-dom'
import { setTitle } from '../../redux/actions/appBarActions';
import { geocodeByAddress } from 'react-places-autocomplete'

import PlacesAutocomplete from 'react-places-autocomplete';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import Loading from '../../components/loading';
import SideBar from '../../components/sideBar';
import Snackbar from '../../components/snackbar';

class HamburgerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            loading: false,
            error: '',
            open: false
        }
        this.onChange = (address) => this.setState({ address })
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    componentDidMount () {
        SideBar.doGoBack(true, {name: "Nova Hamburgeria"});
        this.props.setTitle('Localizar Hamburgeria', true);
    }
    componentWillUnmount () {
        SideBar.doGoBack(false, null);
    }
    handleSelect (value) {
        if(value.indexOf('Juiz de Fora') === -1)
        {
            this.setState({address:''})
            return;
        }
        this.setState({address:value},this.setState({open:true}));
    }
    handleFormSubmit = () => {
        geocodeByAddress(this.state.address)
            .then(results => {
                console.log(results);
                this.setState({ loading: true })
                Http.post('/hamburger/', {
                    placeId: results[0].place_id,
                    user: this.props.user.user._id
                })
                    .then(response => {
                        this.props.history.replace(`/avaliar/${response._id}`);
                    })
                    .catch(async error => {
                        const err = JSON.parse(error.message);
                        if (err.code === 11000) {
                            Http.get(`/hamburger/?placeId=${err.op.placeId}`)
                                .then(r => {
                                    this.props.history.replace(`/avaliar/${r[0]._id}`);
                                })
                        } else {
                            Rollbar.error('Failed to submit new hamburger', error);
                            Snackbar.show({ message: 'Erro ao cadastrar, tente novamente mais tarde', severity: 'error' });
                        }
                    });
            })
            .catch(e => {
                console.log(e);
                Rollbar.error('Failed to submit new hamburger', e);
                Snackbar.show({ message: 'Erro ao cadastrar, tente novamente mais tarde', severity: 'error' });
            });
    }
    render () {
        const inputProps = {
            value: this.state.address,
            onChange: this.onChange,
        }
        const myStyles = {
            root: {
                zIndex: 1,
                width: '98%',
                margin: '0 auto'
            },
            input:
                {
                    border: 0,
                    borderBottom: '1px solid #B71C1C',
                    width: '75%',
                    minWidth: 300,
                    padding: 2,
                    paddingTop: 5,
                    paddingBottom: 5,
                    margin: 3,
                    fontFamily: 'sans-serif',
                    fontSize: '1em'
                },
            autocompleteItem: { color: 'black', },
            autocompleteItemActive: { color: 'blue' },
            autocompleteContainer: { position: 'relative' }
        }
        const options = {
            location: new window.google.maps.LatLng(-21.760328, -43.352411),
            radius: 2,
            types: ['establishment'],

        }
        if(this.state.loading)
        {
            return <Loading/>
        }
        return (
            <div style={{paddingTop:10}} >
                <span style={{ fontSize: '0.8em', color: 'rgba(0,0,0,0.3)' }}>Digite o nome para procurar</span>
                <PlacesAutocomplete onSelect={(value) => this.handleSelect(value)} autocompleteItem={renderSuggestion} styles={myStyles} inputProps={inputProps} options={options} />
                <div className='errorHandler'>
                    {this.state.error}
                </div>
                <Dialog
                    open={this.state.open}
                    onClose={() => this.setState({address:'', open:false})}
                >
                    <DialogContent>
                    <DialogContentText>
                        Deseja realmente adicionar {"  " + this.state.address.split(' - ')[0]}?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => this.setState({address:'', open:false})} color="primary">
                        Não
                    </Button>
                    <Button onClick={() => this.handleFormSubmit()} color="primary" autoFocus>
                        Sim
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const renderSuggestion = ({ formattedSuggestion }) => {
    if (formattedSuggestion.secondaryText.indexOf('Juiz de Fora') === -1) {
        return null;
    }
    return (
        <div >
            <strong>{formattedSuggestion.mainText}</strong>{' - '}
            <small>{formattedSuggestion.secondaryText.split(',')[0]}</small>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapActions = {
    setTitle
}

export default withRouter(connect(mapStateToProps, mapActions)(HamburgerForm));
