import React, { Component } from 'react';
import { 
    TextField, 
    Button, 
    Grid, 
    FormControlLabel, 
    FormControl, 
    FormLabel,
    FormHelperText,
    RadioGroup, 
    Radio, 
    Dialog, 
    DialogContent, 
    DialogContentText, 
    DialogActions
} from '@material-ui/core';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Star from '@material-ui/icons/Star';
import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import IconButton from 'material-ui/IconButton';
import { connect } from 'react-redux';
import { Http, Rollbar } from '@app-masters/js-lib';
import { setTitle } from '../../redux/actions/appBarActions';
import Loading from '../../components/loading';
import SideBar from '../../components/sideBar';
import Snackbar from '../../components/snackbar';
import {isMobile} from '../../utils/constants';

import './styles.css';
import Rating from '@material-ui/lab/Rating';

const styles = {
    category: {
        letterSpacing: 0,
        fontSize: '1em',
        display: 'inline'
    },
    list: {
        textAlign: 'left',
        // minWidth: 310,
        padding: 3,
        marginBottom: 5
    },
    listItem: {
        minHeight: 45,
        letterSpacing: 3,
        fontSize: '1.3em'
    },
    formItem: {
        marginBottom: 16
    },
}

class AvaliarHamburger extends Component {
    constructor (props) {
        super(props);
        this.state = {
            rating: {
                size: null,
                price: 0,
                taste: 0,
                meat: 0,
                notes: '',
                place: null,
                service: 0,
                ambiance: 0,
                warm: null,
                time: 0,
                type: 'podrao'
            },
            place: {
                name: ''
            },
            loading: true,
            error: null
        }
        this.countFiveStars = this.countFiveStars.bind(this);
    }
    componentDidMount () {
        SideBar.doGoBack(true, {name: "Avaliar Hamburgeria"});
        this.setState({ loading: true });
        Http.get(`/hamburger/${this.props.match.params.id}`)
            .then(r => {
                this.setState({
                    burger: r,
                    loading: false
                })
                SideBar.doGoBack(true, {name: `Avaliar ${r.place.name}`});
            })
            .catch(e => {
                console.log(e);
                Rollbar.error('Failed to load hamburger data', e);
                Snackbar.show({ message: 'Erro ao obter dados', severity: 'error' });
            });
    }
    componentWillUnmount () {
        SideBar.doGoBack(false, null);
    }
    validateBody = () => {
        let s = this.state.rating;
        if (!s.size || !s.price || !s.taste || !s.meat || !s.place) {
            return false;
        } else {
            if (s.place === 'delivery') {
                if (!s.warm || !s.time) {
                    return false;
                }
                return true;
            } else {
                if (!s.service || !s.ambiance) {
                    return false;
                }
                return true;
            }
        }
    }
    countFiveStars () {
        let count = 0;
        const { rating } = this.state;
        console.log(rating);
        for (let key in rating) {
            if (rating[key] === 5) {
                count++;
            }
        }
        return count;
    }
    handleSubmit (confirm) {
        if (!confirm && this.countFiveStars() >= 3) {
            this.setState({ open: true });
            return;
        }
        let { rating } = this.state;
        let average = rating.taste*3 + rating.meat;
        if (rating.place === 'delivery') {
            average += rating.warm + rating.time;
        } else {
            average += rating.ambiance + rating.service;
        }
        average /= 6;
        if (this.validateBody()) {
            this.setState({ loading: true })
            Http.post(`/rating`, {
                ...rating,
                user: this.props.user.user._id,
                hamburger: this.state.burger._id,
                average
            }).then(r => {
                window.ga('send', 'event', 'Hamburgueria', 'Avaliada', `${this.state.burger.place.name}`);
                console.log(r);
                this.setState({ loading: false });
                this.props.history.replace(`/place/${r.id}`, { burger: r.hamburger })
            })
                .catch(e => {
                    console.log('erro', e)
                    Rollbar.error('Failed to post review', e);
                    Snackbar.show({ message: 'Erro ao enviar avaliação, tente novamente mais tarde', severity: 'error' });
                });
        }
        else { alert("Preencher todos os campos obrigatórios"); }
    }
    handleTypeChange = (event, value) => {
        let { rating } = this.state;
        rating['type'] = value;
        this.setState({ rating });
    }
    handleRadio = (event, value) => {
        let { rating } = this.state;
        rating['size'] = value;
        this.setState({ rating });
    }
    handleFormChange = (event, name) => {
        let { rating } = this.state;
        rating[name] = event.target.value;
        this.setState({ rating });
    }
    handlePlaceChange = (event, value) => {
        let { rating } = this.state;
        rating['place'] = value;
        if (value === 'delivery') {
            rating['service'] = 0;
            rating['ambiance'] = 0;
        } else {
            rating['warm'] = null;
            rating['time'] = 0;
        }
        this.setState({ rating });
    }
    handleCheck = () => {
        let { rating } = this.state;
        rating['warm'] = !rating['warm'];
        this.setState({ rating })
    }
    handleChangeRating = (name, value) => {
        let { rating } = this.state;
        rating[name] = value;
        this.setState({ rating });
    }
    renderBurguerRating = (place) => (
        <div>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <FormControl component="div" className="evaluate rating form-control">
                        <FormLabel className="evaluate rating label">Sabor</FormLabel>
                        <Rating
                            name={'taste'}
                            value={this.state.rating.taste}
                            onChange={(e, value) => this.handleChangeRating('taste', value)}
                            precision={0.5}
                            icon={<Star fontSize="medium" color="inherit" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl component="div" className="evaluate rating form-control">
                        <FormLabel className="evaluate rating label">Ponto da carne</FormLabel>
                        <Rating
                            name={'meat'}
                            value={this.state.rating.meat}
                            onChange={(e, value) => this.handleChangeRating('meat', value)}
                            precision={0.5}
                            icon={<Star fontSize="medium" color="inherit" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth component="div">
                        <div className="evaluate rating form-control">
                            <FormLabel className="evaluate rating label">
                                Preço
                            </FormLabel>
                            <Rating
                                name={'price'}
                                value={this.state.rating.price}
                                onChange={(e, value) => this.handleChangeRating('price', value)}
                                precision={0.5}
                                style={{color: '#85bb65'}}
                                icon={<AttachMoney fontSize="medium" color="inherit" />}
                            />
                        </div>
                        <FormHelperText>Quanto mais <i style={{ fontSize: '0.8em', color: '#85bb65', marginLeft: 0 }} className="fas fa-dollar-sign" />, mais caro o hamburger</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                        <FormLabel>Tamanho</FormLabel>
                        <RadioGroup aria-label="tamanho" name="size" onChange={this.handleRadio}>
                            <FormControlLabel value="pequeno" control={<Radio />} label="Pequeno" />
                            <FormControlLabel value="medio" control={<Radio />} label="Medio" />
                            <FormControlLabel value="grande" control={<Radio />} label="Grande" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                        <FormLabel>Tipo de Hamburger</FormLabel>
                        <RadioGroup aria-label="tipo de hamburger" name='place' onChange={this.handleTypeChange}>
                            <FormControlLabel value="artesanal" control={<Radio />} label="Artesanal" />
                            <FormControlLabel value="podrao" control={<Radio />} label='"Podrão"' />
                            <FormControlLabel value="fast-food" control={<Radio />} label="Fast Food" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                        <FormLabel>Como foi seu pedido</FormLabel>
                        <RadioGroup aria-label="tamanho" onChange={this.handlePlaceChange} name='place'>
                            <FormControlLabel value="location" control={<Radio />} label="No local" />
                            <FormControlLabel value="delivery" control={<Radio />} label='Delivery' />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            { place && 
                (place !== 'delivery' ? (
                    <Grid container>
                        <Grid item xs={12} sm={6} >
                            <FormControl component="div" className="evaluate rating form-control">
                                <FormLabel className="evaluate rating label">Atendimento</FormLabel>
                                <Rating
                                    name={'service'}
                                    value={this.state.rating.service}
                                    onChange={(e, value) => this.handleChangeRating('service', value)}
                                    precision={0.5}
                                    icon={<Star fontSize="medium" color="inherit" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl component="div" className="evaluate rating form-control">
                                <FormLabel className="evaluate rating label">Ambiente</FormLabel>
                                <Rating
                                    name={'ambiance'}
                                    value={this.state.rating.ambiance}
                                    onChange={(e, value) => this.handleChangeRating('ambiance', value)}
                                    precision={0.5}
                                    icon={<Star fontSize="medium" color="inherit" />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12} sm={6} >
                            <FormControl component="div" className="evaluate rating form-control">
                                <FormLabel className="evaluate rating label">Chegou quente</FormLabel>
                                <Rating
                                    name={'warm'}
                                    value={this.state.rating.warm}
                                    onChange={(e, value) => this.handleChangeRating('warm', value)}
                                    precision={0.5}
                                    icon={<Star fontSize="medium" color="inherit" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl component="div" className="evaluate rating form-control">
                                <FormLabel className="evaluate rating label">Entrega</FormLabel>
                                <Rating
                                    name={'time'}
                                    value={this.state.rating.time}
                                    onChange={(e, value) => this.handleChangeRating('time', value)}
                                    precision={0.5}
                                    icon={<Star fontSize="medium" color="inherit" />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                ))
            }
        </div>
    )
    renderForm = () => {
        return (
            <div style={styles.list}>
                {this.renderBurguerRating(this.state.rating.place)}
                <div>
                    <TextField
                        id="standard-multiline-static"
                        label="Comentários opcionais"
                        placeholder="Diga o que você achou..."
                        onChange={(event) => this.handleFormChange(event, 'notes')}
                        value={this.state.rating.notes}
                        multiline
                        fullWidth
                        variant="outlined"
                        rows={2}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
                    <Button variant="contained" color="primary" onClick={() => this.handleSubmit(false)}>
                        Avaliar
                    </Button>
                </div>
            </div>
            
        );
    }
    render () {
        const history = this.props.history;

        if (this.state.loading) {
            return <Loading />
        }
        return (
            <div className="evaluate container">
                {!isMobile()
                ? <div className={"evaluate header"}>
                    <IconButton onClick={() => history.goBack()}>
                        <ChevronLeft style={{width: 45, height: 45, marginRight: 15}} />
                    </IconButton>
                    <h1 className={"evaluate title"}>{`Avaliar ${this.state.burger.place.name}`}</h1>
                </div> : null}
                <div className="evaluate content">
                    <Grid container>
                        <Grid item xs={12}>
                            {this.renderForm()}
                            <Dialog
                                open={this.state.open || false}
                                onClose={() => this.setState({ open: false })}
                            >
                                <DialogContent>
                                    <DialogContentText >
                                        Vimos que você marcou cinco estrelas para vários itens. Você acredita que esta hamburgeria é uma das melhores de Juiz de Fora?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={() => this.setState({ open: false })} color="primary">
                                    Não
                                </Button>
                                <Button onClick={() => this.handleSubmit(true)} color="primary" autoFocus>
                                    Sim
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapActions = {
    setTitle
}

export default connect(mapStateToProps, mapActions)(AvaliarHamburger);
