import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import './styles.css';
import HamburgerItem from '../../components/hamburgerItem';
import {useWindowSize} from '../../utils/constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import SideBar from '../../components/sideBar';
import { useHistory } from 'react-router-dom';
import { Http, Rollbar } from '@app-masters/js-lib';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Loading from '../../components/loading';
import Snackbar from '../../components/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLocation } from '../../redux/actions/locationActions';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import _ from 'lodash';

import ToggleButton from '@material-ui/lab/ToggleButton';
import FilterList from '@material-ui/icons/FilterList';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import geodist from 'geodist';
import { FormControlLabel, FormControl, FormGroup } from '@material-ui/core';
import { IconButton } from 'material-ui';
import ModalUp from '../../components/modalUp';

const Home = () => {
    const [,, isMobile] = useWindowSize();
    const [modalFilter, setModalFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [dataSort, setDataSort] = React.useState('rank');
    const [filterOpen, setFilterOpen] = React.useState(false);

    const userLocation = useSelector(store => store.location.location);

    const dispatch = useDispatch();
    const history = useHistory();

    
    const verifyOpen = ({place}) => {
        if(!place.opening_hours || !place.opening_hours.weekday_text) return false;
        const day = place.opening_hours.weekday_text[moment().day() - 1];
        if(!day || day.includes("Fechado") || day.includes("Closed")) return false;
        if(day.includes("Atendimento 24")) return true;

        const times = day.replace(day.split(":")[0] + ':', "").replace("AM", "").replace("PM", "").trim().split(" – ");

        const openingTime = times[0].split(":");
        const closeTime = times[1].split(":");

        const todayOpening = moment().set({hour: openingTime[0], minute: openingTime[1]});
        const todayClose = moment().set({hour: closeTime[0], minute: closeTime[1]});
        return moment().isAfter(todayOpening) && moment().isBefore(todayClose);
    };

    const getData = useCallback((retries = 0, err) => {
        setLoading(true);
        if (err && retries > 1) {
            console.log(err.message);
            Rollbar.error('Failed to load hamburger list', err);
            Snackbar.show({ message: 'Erro ao obter dados do servidor', severity: 'error' });
            return;
        }
        Http.get(`/hamburger?sort=-weekAverage`)
            .then(data => {
                let hamburger = data.filter(f => f.place);

                hamburger = hamburger.map(burger => {
                    burger.weekAverage = burger.weekAverage || 0;
                    burger.isOpen = verifyOpen(burger);
                    if(burger.place && burger.place.geometry)
                        burger.distance = geodist(burger.place.geometry.location, userLocation, {exact: true, unit: 'km'});
                    const firstPhoto = burger.selectedPhoto || 0;
                    let array = burger.place.photos ? [...burger.place.photos] : [];
                    array.splice(0, 0, array.splice(firstPhoto, 1)[0]);
                    return {...burger, place: {...burger.place, photos: array}};
                });

                //Sort by rank
                hamburger = _.sortBy(hamburger, item => {return !item.weekAverage});
                //Must set the ranking after the sort
                hamburger = hamburger.map((item, index) => {return {...item, rank: index}})

                localStorage.setItem('dashboard', JSON.stringify(hamburger));
                setLoading(false);
                setData(hamburger);
                setFilteredData(hamburger);
                setDisplayData(hamburger.slice(0, 16));
            })
            .catch(e => getData(retries + 1, e));
    }, [userLocation]);

    const geolocation = useCallback(async () => {
        if(!userLocation)
            await navigator.geolocation.getCurrentPosition(
                position => {
                    const coords = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude 
                    };
                    dispatch(setCurrentLocation(coords));
                }, 
                err => {
                    if(err.message.includes('denied')) {
                        dispatch(setCurrentLocation('denied'));
                    }
                }
            );
    }, [dispatch, userLocation]);

    useEffect(() => {
        if(userLocation) {
            const dashboard = JSON.parse(localStorage.getItem('dashboard'));
            if (dashboard) {
                setData(dashboard);
                setFilteredData(dashboard);
                setDisplayData(dashboard.slice(0, 16));
            }
            getData();
        }
    }, [getData, userLocation]);

    useEffect(() => {
        SideBar.doGoBack(false);
        SideBar.toggleSearch();
        geolocation();
        return () => {
            SideBar.toggleSearch(false, null);
        }
    }, [geolocation]);

    const handleDataSort = (e, newDataSort) => {
        if (newDataSort !== null) {
            let list;
            setDataSort(newDataSort);
            if(newDataSort === 'rank') {
                list =  _.sortBy([...filteredData], item => {return item.rank});
            } else if (newDataSort === 'range') {
                list =  _.sortBy(filteredData, item => {return item.distance});
            }
            setDisplayData(list.slice(0, displayData.length));
            setFilteredData(list);
        }
    }

    const onFilterPlace = useCallback((e) => {
        const query = e.target.value;
        setDataSort("rank");
        if (query === "") {
            setFilteredData(data);
            setDisplayData(data.slice(0, displayData.length));
        } else {
            const list = data.filter(f => f.place.name.toLowerCase().includes(query.toLowerCase()));
            setFilteredData(list);
            setDisplayData(list.slice(0, displayData.length));
        }
    }, [data, displayData.length]);

    const onFilterOpen = (event) => {
        setFilterOpen(event.target.checked);
        if(event.target.checked) {
            const list = _.filter(data, item => {return item.isOpen});
            setFilteredData(list);
            setDisplayData(list.slice(0, displayData.length));
        } else {
            setFilteredData(data);
            setDisplayData(data.slice(0, displayData.length));
        }
    }

    const renderMoreData = () => {
        setDisplayData([...displayData, ...filteredData.slice(displayData.length, displayData.length + 8)])
    }

    const FilterMobile = () => {
        return (
            <IconButton onClick={() => setModalFilter(true)} style={{color: "#FFFFFF"}}>
                <FilterList color='inherit' />
            </IconButton>
        )
    }

    useEffect(() => {
        if (data.length > 0) {
            SideBar.toggleSearch(true, onFilterPlace, <FilterMobile />);
        }
    }, [data, onFilterPlace]);

    return (
        <div className={"ListContainer"}>
            <div className={"ListContent"}>
                {!isMobile ?
                    <Grid container className="ListHeader">
                        <Grid item sm={12} md={12} lg={12}>
                            <h1 className="ListHeaderLabel">Hamburguerias</h1>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={12} lg={6} className={"ListSearch"}>
                                    <Input
                                        placeholder={"Pesquisar"}
                                        id='input-with-icon-adornment'
                                        fullWidth
                                        style={{maxWidth: 600, marginRight: 10}}
                                        onChange={onFilterPlace}
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <Search />
                                            </InputAdornment>
                                        }
                                    />
                                    <Button className="HeaderAddButton" color={"primary"} variant="contained" onClick={() => history.push("/hamburger/new")}>
                                        Incluir hamburgeria
                                    </Button>
                                </Grid>
                                <Grid item sm={12} md={12} lg={6}>
                                    <Grid container>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Grid container spacing={2} style={{justifyContent: 'center'}}>
                                                {!(!userLocation || userLocation === "denied") && (
                                                    <Grid item sm={'auto'} md={'auto'} lg={'auto'} className={"ListSort"}>
                                                        <ToggleButtonGroup
                                                            size="small"
                                                            style={{marginLeft: 8}}
                                                            value={dataSort}
                                                            exclusive
                                                            onChange={handleDataSort}
                                                            aria-label="data sort"
                                                        >
                                                            <ToggleButton value="rank" aria-label="rank sort">
                                                                melhor avaliação
                                                            </ToggleButton>
                                                            <ToggleButton value="range" aria-label="range sort">
                                                                menor distância
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Grid>)}
                                                <Grid item sm={'auto'} md={'auto'} lg={'auto'} className={"ListSort"}>
                                                    <FormControlLabel
                                                        style={{marginLeft: 8}}
                                                        control={<Checkbox color="primary" checked={filterOpen} onChange={onFilterOpen} name="checkedA" />}
                                                        label="Abertos agora"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                 :
                <Fab color="primary" aria-label="add" className={"FabAdd"} onClick={() => history.push("/hamburger/new")}>
                    <Add color={"inherit"} />
                </Fab>}
                {data.length === 0 && loading ? <Loading /> 
                : displayData.length === 0 && filterOpen ? <div className={"ListEmpty"}>Nenhuma hamburgeria aberta agora</div> 
                : <InfiniteScroll
                    pageStart={0}
                    loadMore={renderMoreData}
                    hasMore={displayData.length !== filteredData.length}
                    style={{
                        display: "flex",
                        flexWrap: "wrap"
                    }}
                >
                    {displayData.map((item, index) => 
                        <div key={index} className={"ItemContainer"} onClick={() => history.push(`/place/${item.id}`)}>
                            <HamburgerItem item={item} showAddress={dataSort === 'range'} />
                        </div>
                    )}
                </InfiniteScroll>}
            </div>
            <ModalUp open={modalFilter} title={"Filtros"} closeModal={() => setModalFilter(false)}>
                <FormControl>
                    <FormGroup>
                        {!(!userLocation || userLocation === "denied") && <FormControlLabel
                            value="sort"
                            className={"FormItem"}
                            control={
                                <ToggleButtonGroup
                                    size="small"
                                    style={{paddingTop: 4}}
                                    value={dataSort}
                                    exclusive
                                    onChange={handleDataSort}
                                    aria-label="data sort"
                                >
                                    <ToggleButton value="rank" aria-label="rank sort">
                                        melhor avaliação
                                    </ToggleButton>
                                    <ToggleButton value="range" aria-label="range sort">
                                        menor distância
                                    </ToggleButton>
                                </ToggleButtonGroup>}
                            label="Ordenar por"
                            labelPlacement="top"
                            />}
                        <FormControlLabel
                            value="filter"
                            className={"FormItem"}
                            control={
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={filterOpen} onChange={onFilterOpen} name="checkedA" />}
                                    label="Abertos agora"
                                />}
                            label=""
                            labelPlacement="top"
                            />
                    </FormGroup>
                </FormControl>
            </ModalUp>
        </div>
    );
};

export default Home;
