import React, { useState, useContext, useCallback, useMemo } from "react";
import './styles.css';

import Loading from '../loading';
import Burguer from '../../assets/burger512.png';
import Input from '@material-ui/core/Input';

// Old
import Drawer from 'material-ui/Drawer';
import IconButton from 'material-ui/IconButton';

import Exit from '@material-ui/icons/ExitToApp';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Remove from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import Home from '@material-ui/icons/Home';
import Menu from '@material-ui/icons/Menu';
import Info from '@material-ui/icons/Info';
import Star from '@material-ui/icons/Star';

import CertifiedDark from '../../assets/certifiedDark.png';
import {useWindowSize} from '../../utils/constants';
import AccountMenu from '../accountMenu';
import { useHistory, useLocation } from "react-router-dom";
import { Divider } from "material-ui";
import { useSelector } from "react-redux";

const initialState = {
    hasProvider: false,
    loading: false,
    showLoading: () => {
        throw new Error("No ModalProvider was found");
    },
    closeLoading: () => {
        throw new Error("No ModalProvider was found");
    },
    toggleSearch: () => {
        throw new Error("No ModalProvider was found");
    }
};

const SideBarContext = React.createContext(initialState);
let globalState = initialState;

const home = [
    {
        label: "Inicio",
        icon: <Home color={'inherit'} />,
        location: '/'
    },
    {
        label: "Sobre",
        icon: <Info color={'inherit'} />,
        location: '/about'
    },
    {
        label: "Minhas Avaliações",
        icon: <Star color={'inherit'} />,
        location: '/reviews'
    }
];

export const SideBarProvider = ({children}) => {
    const [,,isMobile] = useWindowSize();
    const [goBack, setGoBack] = useState({goBack: false, place: null});
    const [loading, setLoading] = useState(true);
    const [search, setSearchDisplay] = useState({show: false, callback: null, extra: null});
    const toggleSearch = useCallback((show, event, extra) => setSearchDisplay({show, callback: event, extra}), []);
    const showLoading = useCallback(() => setLoading(true), []);
    const closeLoading = useCallback(() => setLoading(false), []);
    const doGoBack = useCallback((goBack, place) => setGoBack({goBack, place}), []);

    const history = useHistory();

    globalState = {
        hasProvider: true,
        showLoading,
        closeLoading,
        toggleSearch,
        doGoBack
    };

    return (
        <SideBarContext.Provider value={globalState}>
            <div className={`AppSideBar ${!loading ? 'SideBarAnimate' : ''}`}>
                {!loading ? (
                    <img
                        src={Burguer}
                        className={`SideBarImage ${!loading ? 'SideBarImageAnimate' : ''}`}
                        onClick={() => history.push("/")}
                        alt={"burguer-logo"}
                    />
                ) : (
                    <Loading />
                )}
                {!loading ? isMobile ? <MobileNavBar search={search} navigate={goBack} /> : <WebSideBar /> : null}
            </div>
            <div className={"AppContainer"}>
                {children}
            </div>
        </SideBarContext.Provider>
    );
};

const MobileNavBar = ({search, navigate}) => {
    const [drawer, setDrawer] = useState(false);
    const history = useHistory();
    const user = useSelector(store => store.auth.user);

    return (
        <div style={{width: '100%'}}>
            <div className={"NavBarMobile"}>
                {navigate.goBack
                    ? <IconButton className={"MobileIcon"} onClick={() => history.goBack()}>
                        <ChevronLeft color='inherit' />
                    </IconButton>
                    : <IconButton className={"MobileIcon"} onClick={() => setDrawer(!drawer)}>
                        <Menu color="inherit"/>
                    </IconButton>}
                <h6>{navigate.place ? navigate.place.name : 'GoodBurguer'}</h6>
                {navigate.place && navigate.place.certified ? <img src={CertifiedDark} alt={"certified dark"} style={{width: 30, marginRight: 10, marginLeft: 10}} /> : null}
                {!navigate.goBack && search.show ? <MobileSearch search={search} /> : null}
            </div>
            <Drawer
                containerStyle={{ backgroundColor: '#fafafa' }}
                docked={false}
                open={drawer}
                onRequestChange={(open) => setDrawer(open)}
            >
                <div className={"DrawerImageContainer"}>
                    <img
                        src={Burguer}
                        style={{width: 60}}
                        alt={"burguer-logo"}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DrawerItem Icon={Home} to={"/"} label={"Inicio"} onClick={() => setDrawer(false)} />
                    <DrawerItem Icon={Info} to={"/about"} label={"Sobre"} onClick={() => setDrawer(false)} />
                    <DrawerItem Icon={Star} to={"/reviews"} label={"Minhas Avaliações"} onClick={() => setDrawer(false)} />
                    <Divider />
                    {user ? <DrawerItem Icon={Exit} to={"/logout"} label={"Sair"} onClick={() => setDrawer(false)} /> : null}
                </div>
                <AccountMenu />
            </Drawer>
        </div>
    );
};

const DrawerItem = ({Icon, to, label, onClick}) => {
    const history = useHistory();
    const location = useLocation();

    const active = useMemo(() => {
        const pathname = location.pathname;
        return pathname === to;
    }, [location, to]);

    return (
        <div className={`DrawerItem ${active ? 'drawerItemActive' : ''}`} onClick={() => {
            history.push(to);
            onClick();
        }}>
            <Icon style={{width: 25, height: 25}} color={"inherit"} />
            <div className={`DrawerItemLabel ${active ? 'drawerItemLabelActive' : ''}`} >{label}</div>
        </div>
    );
};

const MobileSearch = ({search}) => {
    const [searchContent, setSearch] = useState(false);
    const [query, setQuery] = useState('');
    return (
        <div style={{flex: 1, zIndex: 2}}>
            {search.show
                ? <div className={"NavBarSearchContainer"}>
                    {search.extra}
                    <IconButton style={{color: '#FFFFFF'}} onClick={() => setSearch(true)}>
                        <Search color={'inherit'} />
                    </IconButton>
                </div> : null}
            {searchContent
                ? <div className={"SearchBarContainer"}>
                    <IconButton onClick={() => setSearch(false)}>
                        <ArrowBack color='inherit' />
                    </IconButton>
                    <Input
                        placeholder={"Pesquisar"}
                        fullWidth
                        value={query}
                        disableUnderline
                        onChange={(e) => {
                            setQuery(e.target.value);
                            search.callback(e);
                        }}
                    />
                    {query.length > 0
                        ? <div className={"NavBarSearchContainer"}>
                            <IconButton onClick={() => {
                                setQuery('');
                                search.callback({target: {value: ""}});
                            }}>
                                <Remove color={"inherit"} />
                            </IconButton>
                        </div> : null }
                </div> : null}
        </div>
    );
};

const WebSideBar = () => {
    const user = useSelector(store => store.auth.user);
    return (
        <div className={"SideBarWeb"}>
            {home.map((nav, index) => <NavigationItem key={index} item={nav} />)}
            {user ?
            <NavigationItem item={{
                label: "Sair",
                icon: <Exit color={"inherit"} />,
                location: '/logout'
            }} /> : null}
            <AccountMenu />
        </div>
    );
};

const NavigationItem = ({item}) => {
    const history = useHistory();
    const location = useLocation();

    const active = useMemo(() => {
        const pathname = location.pathname;
        return pathname === item.location;
    }, [location, item]);

    return (
        <div onClick={() => history.push(item.location)} className={`NavigationItem ${active ? 'itemActive' : ''}`}>
            {item.icon}
        </div>
    );
};

export const useSideBar = () => useContext(SideBarContext);

export const getSideBar = () => globalState;

export default {
    showLoading: () => globalState.showLoading(),
    closeLoading: () => globalState.closeLoading(),
    toggleSearch: (show, event, extra) => globalState.toggleSearch(show, event, extra),
    doGoBack: (goBack, place) => globalState.doGoBack(goBack, place)
};
