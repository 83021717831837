import { AppBootstrap, Rollbar } from '@app-masters/js-lib';

import packag from '../../package.json';
import envs from '../config';
import { storage } from '../redux/store';

const InitializeBootstrap = () => {
  try {
      let callbacks = {
          onMinVersionNotSatifies: (version) => {
              alert("Você deve atualizar sua versão agora! Por favor recarregue a página, se a mensagem continuar, limpe o cache do navegador.");
          },
          onNewVersion: (version) => {
              if (version === '0.2.0') {
                  // Some new version decision
              }
          },
          onUncaughtError: (e) => {
              if (e.message !== 'Failed to fetch') {
                  Rollbar.error(e);
                  alert("Houve um erro inesperado e os programadores responsáveis já foram avisados. \n\n Detalhes técnicos: " + e.message);
              } else {
                  alert('Falha na conexāo');
              }
          }
      };
      // Bootstrap
      AppBootstrap.setup("web", packag, envs, storage, callbacks);
  } catch (e) {
      alert("Houve um erro inesperado e os programadores responsáveis já foram avisados. \n\n Detalhes técnicos: " + e.message);
  }
};

InitializeBootstrap();