import React from 'react';
import Card from '../cardItem';
import {CardHeader, CardInfo} from '../cardItem/header';
import {CardBody} from '../cardItem/body';
import './styles.css';
import { getPlacesImages } from '../../utils/constants';
import { Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import AttachMoney from '@material-ui/icons/AttachMoney';
import moment from 'moment';

const ReviewItem = ({item}) => {
    const displayImage = getPlacesImages(item.hamburger.place);
    const parseType = (type) => {
        const types = [
            'fast-food',
            'podrao',
            'artesanal'
        ];
        const names = [
            'Fast Food',
            'Podrão',
            'Artesanal'
        ];
        return (
            names[types.indexOf(type)]
        );
    }
    return (
        <Card>
            <CardHeader image={displayImage} imageAlt={item.hamburger.place.name} />
            <CardBody>
                <CardInfo name={item.hamburger.place.name} rating={item.average} />
                <Grid container className={"InfoGrid"}>
                    <Grid item xs={6} md={6} lg={6} className={"ItemSubtitle"}>
                        {moment(item.created_at).fromNow()}
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} className={"ItemTitle"}>
                        {parseType(item.type)}
                    </Grid>
                </Grid>
                <Grid container style={{paddingTop: 8}}>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Sabor
                        <Rating
                            name={'taste'}
                            value={item.taste}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Tamanho
                        <div className={"ItemSize"}>
                            {item.size}
                        </div>
                    </Grid>
                </Grid>
                <Grid container style={{paddingTop: 8}}>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Carne
                        <Rating
                            name={'meat'}
                            value={item.meat}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Preço
                        <Rating
                            name={'price'}
                            value={item.price}
                            disabled
                            precision={0.5}
                            style={{color: '#5ed916'}}
                            icon={<AttachMoney fontSize="inherit" color="inherit" />}
                        />
                    </Grid>
                </Grid>
                {item.place !== 'delivery' ? 
                <Grid container style={{paddingTop: 8}}>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Serviço
                        <Rating
                            name={'service'}
                            value={item.service}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Ambiente
                        <Rating
                            name={'ambiance'}
                            value={item.ambiance}
                            disabled
                        />
                    </Grid>
                </Grid> 
                : 
                <Grid container style={{paddingTop: 8}}>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Quente
                        <Rating
                            name={'warm'}
                            value={item.warm}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} className={"ItemLabel"}>
                        Entrega
                        <Rating
                            name={'time'}
                            value={item.time}
                            disabled
                        />
                    </Grid>
                </Grid>}
            </CardBody>
        </Card>
    );
};

export default ReviewItem;
