import {FIRST_TIME_CHANGED} from '../actions/types';
const INITIAL_STATE = JSON.parse(localStorage.getItem('hasUsed'))
export default (state=INITIAL_STATE, action) => {
    switch(action.type)
    {
        case FIRST_TIME_CHANGED:
            return action.payload
        default:
            return state;
    }
}