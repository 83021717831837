import React from 'react';
import './styles.css'

const Card = ({children}) => {
  return (
    <div className={"ListItem"}>
      {children}
    </div>
  )
}

export default Card;