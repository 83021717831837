import {ThemeProvider} from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { store } from './redux/store';
import { Provider } from 'react-redux';

import theme from './styles/old_theme';
import newTheme from './styles/new_theme';
import React, { useEffect } from 'react';
import Routes from './routes/publicRoutes';
import {BrowserRouter as Router} from 'react-router-dom';

import SideBar, {SideBarProvider} from './components/sideBar';
import ErrorHandler from './components/errorBoundary';
import { SnackbarProvider } from './components/snackbar';
import './services/bootstrap';

const App = () => {
    const onDeviceReady = () => {
        window.ga('send', 'event', 'App', 'Aberto', 'Usuário abriu o aplicativo');
        setTimeout(() => {
            SideBar.closeLoading();
        }, 2000);
    };

    useEffect(() => {
        window.addEventListener("deviceready", onDeviceReady(), false);
    }, []);

    return (
        <Provider store={store}>
            <ThemeProvider theme={newTheme}>
                <MuiThemeProvider theme={theme}>
                    <ErrorHandler>
                        <Router>
                            <SideBarProvider>
                                <Routes />
                            </SideBarProvider>
                        </Router>
                        <SnackbarProvider />
                    </ErrorHandler>
                </MuiThemeProvider>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
