import React from 'react';
import './loading.css';

export default () => (
    <div style={{ position: 'fixed', bottom: 0, right: 0, display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '25%', maxWidth: 120 }} viewBox="0 0 512 512">
            <path d="M442.43 444.37h16.696c12.295 0 22.26 9.966 22.26 22.261 0 24.588-19.933 44.522-44.521 44.522H80.69c-24.588 0-44.522-19.933-44.522-44.522 0-12.295 9.966-22.26 22.26-22.26h16.697z" className="bottomBun" fill="#f4c064" />
            <path className="hamburger" d="M478.609 406.261c0 21.515-17.441 38.957-38.957 38.957H72.348c-21.515 0-38.957-17.441-38.957-38.957 0-21.515 17.441-38.957 38.957-38.957h367.304c21.515 0 38.957 17.442 38.957 38.957z" fill="#99774f" />
            <g className="sallad">
                <path d="M478.609 356.174c0 24.588-19.934 44.522-44.522 44.522-14.594 0-27.54-7.028-35.657-17.878-2.083-2.784-5.387-4.383-8.864-4.383-3.477 0-6.782 1.599-8.864 4.383-8.117 10.85-21.063 17.878-35.657 17.878-14.594 0-27.54-7.028-35.657-17.878-2.082-2.784-5.387-4.383-8.864-4.383-3.477 0-6.782 1.599-8.864 4.383-8.117 10.85-21.063 17.878-35.657 17.878-14.594 0-27.54-7.028-35.657-17.878-2.083-2.784-5.387-4.383-8.864-4.383-3.477 0-6.782 1.599-8.864 4.383-8.117 10.85-21.063 17.878-35.657 17.878-14.594 0-27.54-7.028-35.657-17.878-2.083-2.784-5.387-4.383-8.864-4.383-3.477 0-6.782 1.599-8.864 4.383-8.117 10.85-21.063 17.878-35.657 17.878-24.588 0-44.522-19.934-44.522-44.522 0-43.03 34.883-77.913 77.913-77.913h289.389c43.027 0 77.91 34.883 77.91 77.913z" fill="#91f18b" />
                <path d="M478.609 300.522c0 12.295-9.966 22.261-22.261 22.261H55.652c-12.295 0-22.261-9.966-22.261-22.261s9.966-22.261 22.261-22.261h400.696c12.294 0 22.261 9.966 22.261 22.261z" fill="#ff8355" />
            </g>
            <g className="topBun">
                <path fill="#ffe954" d="M256 356.174a61.486 61.486 0 0 1-39.362-14.251L100.174 244.87h311.652l-116.464 97.053A61.486 61.486 0 0 1 256 356.174z" />
                <path fill="#f4c064" d="M456.348 278.261H55.652c-12.295 0-22.261-9.966-22.261-22.261 0-86.061 69.766-155.826 155.826-155.826h133.565c86.061 0 155.826 69.766 155.826 155.826.001 12.295-9.966 22.261-22.26 22.261z" />
                <path fill="#ffee92" d="M431.304 233.739a8.349 8.349 0 0 1-8.348 8.348h-11.13a8.349 8.349 0 0 1 0-16.696h11.13a8.348 8.348 0 0 1 8.348 8.348zm-52.869-86.261h-11.13a8.349 8.349 0 0 0 0 16.696h11.13a8.349 8.349 0 0 0 0-16.696zm41.739 41.739a8.349 8.349 0 0 0-8.348-8.348h-11.13a8.349 8.349 0 0 0 0 16.696h11.13a8.348 8.348 0 0 0 8.348-8.348zm-64 13.913h-11.13a8.349 8.349 0 0 0 0 16.696h11.13a8.349 8.349 0 0 0 0-16.696z" />
            </g>
            <g className="flag">
                <path d="M356.174 66.783H256V11.13h100.174c6.147 0 11.13 4.983 11.13 11.13v33.391c0 6.149-4.983 11.132-11.13 11.132z" fill="#ff8355" />
                <path d="M264.348 8.348v125.217h-16.696V8.348a8.349 8.349 0 0 1 16.696 0z" fill="#cea05d" />
                <path d="M286.609 133.565a8.349 8.349 0 0 1-8.348 8.348h-55.652a8.349 8.349 0 0 1 0-16.696h55.652a8.349 8.349 0 0 1 8.348 8.348z" fill="#f49744" />
            </g>
        </svg>
    </div>
);
