import React from 'react';

const AppMastersLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 250 }} viewBox="0 0 629.97333 115">
        <g transform="matrix(.69191 0 0 .69191 247.85777 -44.884821)">
            <g transform="translate(-576.64843 45.562054)" fill-rule="evenodd">
                <path 
                    d={
                        "M308.51466 26.546677c15.756 0 30.392 4.802666 42.516 13.023999-2.56134 1.693334-4.58134 " + 
                        "4.144-5.736 7.032-9.63467-5.688-20.86933-8.953333-32.872-8.953333-35.764 0-64.75867 " + 
                        "28.994666-64.75867 64.758667 0 35.76933 28.99467 64.7652 64.75867 64.7652 9.20933 0 " + 
                        "17.96667-1.92814 25.89866-5.39054.92134 3.1624 2.84267 5.89734 5.40134 7.83854-10.52133 " + 
                        "5.5228-22.49867 8.65466-35.208 8.65466-41.89733 0-75.86267-33.9652-75.86267-75.86653 " + 
                        "0-41.897331 33.96534-75.862663 75.86267-75.862663zm63.41333 34.211999c4.87467 7.405333 " + 
                        "8.48667 15.713333 10.53333 24.619999-.61066-.07467-1.23333-.114666-1.86533-.114666-1.916 " + 
                        "0-3.74667.357333-5.436 1.005333-1.89067-7.373333-5.05067-14.24-9.22933-20.353333 " + 
                        "2.42533-1.164 4.496-2.952 5.99733-5.157333zm11.34533 54.606664c-2.31866 13.48-8.19333 " + 
                        "25.74267-16.62533 35.788-1.18133-2.54133-3.04533-4.70267-5.35467-6.244 7.30267-8.38933 " + 
                        "12.47467-18.67733 14.68934-30.024 1.456.464 3.00533.716 4.61333.716.91467 0 " + 
                        "1.808-.0813 2.67733-.236"
                    } 
                    fill="currentColor"
                />
                <path 
                    d={
                        "M307.70799 48.390676c-18.384.269334-34.544 9.716-44.09866 23.966666 2.02133.208 " + 
                        "3.87733.962667 5.41866 2.118667 8.42667-11.053333 21.73067-18.189333 " + 
                        "36.70534-18.189333l.94266.012c-.208-.842667-.32133-1.725333-.32133-2.633333 " + 
                        "0-1.912.49467-3.709334 1.35333-5.274667zm-52.16666 43.350666c-.688 3.449333-1.056 " + 
                        "7.014666-1.056 10.667998 0 16.12267 7.06133 30.592 18.25733 40.48933.55867-2.052 " + 
                        "1.70133-3.86266 3.23467-5.248-10.012-8.46-16.36934-21.108-16.36934-35.24133 " + 
                        "0-2.867998.26534-5.673332.76534-8.397332-1.808-.352-3.46-1.148-4.832-2.270666zm35.504 " + 
                        "61.813328c5.47866 1.86934 11.35866 2.888 17.472 2.888 29.83999 0 54.03066-24.18933 " + 
                        "54.03066-54.03333 0-22.911998-14.26267-42.489331-34.39333-50.347997.076.524.116 " + 
                        "1.06.116 1.604 0 2.721333-.99067 5.209333-2.63467 7.126666 15.50933 7.430667 26.21867 " + 
                        "23.273333 26.21867 41.617331 0 25.47467-20.65067 46.124-46.12133 46.124-3.98267 " + 
                        "0-7.848-.50267-11.53467-1.452-.296 2.51733-1.448 4.77467-3.15333 6.47333"}
                    fill="#3f92cf"
                />
                <path 
                    d={
                        "M352.88399 147.54667c5.51733 0 9.98667 4.472 9.98667 9.98667 0 5.516-4.46934 9.9868-9.98667 " + 
                        "9.9868-5.516 0-9.98667-4.4708-9.98667-9.9868 0-5.51467 4.47067-9.98667 9.98667-9.98667"
                    }
                    fill="#3f92cf"
                />
                <path 
                    d={
                        "M359.38399 42.24401c5.51467 0 9.98667 4.472 9.98667 9.986666 0 5.517333-4.472 9.988-9.98667 " + 
                        "9.988-5.51867 0-9.98933-4.470667-9.98933-9.988 0-5.514666 4.47066-9.986666 9.98933-9.986666"
                    }
                    fill="#3f92cf"
                />
                <path 
                    d={
                        "M262.47333 76.430675c3.76933 0 6.82666 3.057334 6.82666 6.825334 0 3.769333-3.05733 " + 
                        "6.826666-6.82666 6.826666-3.76934 0-6.82534-3.057333-6.82534-6.826666 0-3.768 " + 
                        "3.056-6.825334 6.82534-6.825334"
                    }
                    fill="#3f92cf"
                />
                <path 
                    d={
                        "M283.31599 138.96001c3.77067 0 6.82667 3.056 6.82667 6.82533s-3.056 6.82667-6.82667 " + 
                        "6.82667c-3.768 0-6.824-3.05734-6.824-6.82667s3.056-6.82533 6.824-6.82533"
                    } 
                    fill="currentColor"
                />
                <path 
                    d={
                        "M317.31333 46.84001c3.76933 0 6.82533 3.055999 6.82533 6.825333 0 3.770666-3.056 " + 
                        "6.826666-6.82533 6.826666-3.76934 0-6.82534-3.056-6.82534-6.826666 0-3.769334 " + 
                        "3.056-6.825333 6.82534-6.825333"
                    } 
                    fill="currentColor"
                />
                <path 
                    d={
                        "M308.51733 70.881342c17.07066 0 30.90799 13.838667 30.90799 30.907998 0 17.07067-13.83733 " + 
                        "30.90933-30.90799 30.90933-17.07067 0-30.908-13.83866-30.908-30.90933 0-17.069331 " + 
                        "13.83733-30.907998 30.908-30.907998zm11.48 19.104l-10.15467 18.735998-1.74533 " + 
                        "3.16667-1.57734-3.164-9.91733-18.294668-.136.146666c-2.79067 3.029334-4.332 6.976-4.332 " + 
                        "11.096002 0 5.34266 2.568 10.3 6.89733 13.36l2.49334 1.50666-2.972 " + 
                        "6.50667-1.49334-.77733c-7.48-4.16934-12.10666-12.03334-12.10666-20.596 0-8.968002 " + 
                        "4.93066-16.804001 12.82-20.974668l1.74133-.918667 2.31067 4.764 6.35466 12.565333 " + 
                        "6.46-12.759999 2.30534-4.766667 1.70666.814667c8.208 3.909333 13.43067 12.183999 13.43067 " + 
                        "21.276001 0 8.56266-4.62667 16.42533-12.124 20.60533l-1.51733.804-3.00267-6.50533 " + 
                        "2.532-1.52267c4.364-3.08 6.92933-8.03867 6.92933-13.38133 " + 
                        "0-4.337335-1.708-8.477335-4.768-11.552002l-.13466-.134666"
                    } 
                    fill="currentColor"
                />
                <path 
                    d={
                        "M380.59599 90.446675c5.516 0 9.98667 4.470667 9.98667 9.986665 0 5.51467-4.47067 " + 
                        "9.98667-9.98667 9.98667s-9.98667-4.472-9.98667-9.98667c0-5.515998 4.47067-9.986665 " + 
                        "9.98667-9.986665"
                    } 
                    fill="currentColor"
                />
                </g>
                <g transform="matrix(1.2373 0 0 1.2373 -202.64952 -150.27517)" fill="currentColor">
                    <path 
                        d={
                            "M151.45066 270.02534h-7.08933v-57.87453h16.04133c2.39334 0 4.596.1464 6.632.4276 2.04.29" +
                            "64 3.90134.73906 5.59867 1.34533 3.45733 1.24013 6.08667 3.1016 7.87467 5.56827 1.788 2." + 
                            "46666 2.67333 5.512 2.67333 9.11453 0 2.94173-.57467 5.53746-1.74267 7.76933-1.16666 2.2" +
                            "4427-2.86533 4.10573-5.08266 5.61347-2.21734 1.5068-4.90267 2.6292-8.09334 3.39586-3.177" +
                            "33.7708-5.61333 1.1536-9.648 1.1536-.54533 0-1.1-.008-1.66133-.0228l2.09733-6.38653c3.03" +
                            "334.10933 3.824-.10253 6.78934-.75733 2.332-.51187 4.236-1.26814 5.70266-2.25614 1.476-1" +
                            ".00426 2.53867-2.2172 3.20267-3.6376.66533-1.42906.99067-3.05573.99067-4.87186 0-2.24787" + 
                            "-.54667-4.0948-1.62267-5.54107-1.08-1.4324-2.616-2.524-4.596-3.28026-1.24-.45734-2.672-." +
                            "7812-4.284-.97454-1.61067-.19213-3.48667-.27853-5.61333-.27853h-8.16934v51.4932z"
                        } 
                        fill-rule="evenodd"
                    />
                    <path 
                        d={
                            "M31.696399 270.02534l23.533866-57.87453h6.113467l23.622399 57.87453h-7.668266l-6.794267-" +
                            "17.37187-2.555733-6.37867-2.230667-5.52546c-2.3776-6-4.802133-12.5428-7.297866-19.648h-." + 
                            "176133c-2.601467 7.2068-5.083334 13.76666-7.432267 19.65986l-2.2276 5.5136h.0011c-2.9974" +
                            "67 7.9916-6.072933 15.51454-9.309867 23.75054z"
                        } 
                        fill-rule="evenodd"
                    />
                    <path 
                        d={
                            "M100.90213 270.02534h-7.091199v-57.87453h16.041729c2.39574 0 4.59627.1464 6.63534.4276 2" +
                            ".03546.2964 3.8984.73906 5.598 1.34533 3.45573 1.24013 6.08333 3.1016 7.87333 5.56827 1." +
                            "78547 2.46666 2.67347 5.512 2.67347 9.11453 0 2.94173-.57707 5.53746-1.74214 7.76933-1.1" +
                            "6826 2.24427-2.8688 4.10573-5.08333 5.61347-2.2152 1.5068-4.90373 2.6292-8.0964 3.39586-" +
                            "3.17347.7708-5.60827 1.1536-9.64213 1.1536-.53134 0-1.074-.008-1.61827-.0213l2.29787-6.3" +
                            "8693c3.00786.1 3.61093-.10934 6.54426-.7584 2.32814-.51187 4.236-1.26814 5.69947-2.25614" +
                            " 1.47507-1.00426 2.54013-2.2172 3.20427-3.6376.6656-1.42906.99106-3.05573.99106-4.87186 " +
                            "0-2.24787-.5468-4.0948-1.62653-5.54107-1.07653-1.4324-2.61307-2.524-4.59373-3.28026-1.23" +
                            "8-.45734-2.67027-.7812-4.28227-.97454-1.612-.19213-3.486-.27853-5.6136-.27853h-8.1692v51" +
                            ".4932"
                        } 
                        fill-rule="evenodd"
                    />
                    <path 
                        d={
                            "M255.98133 212.06801l-13.54934 39.38133c-.67466 1.97186-1.25866 3.70146-1.75333 5.19053-" + 
                            ".42267-1.3-.91333-2.8036-1.476-4.5052l-13.396-40.06666h-13.176v57.88026h9.55733v-40.8521" +
                            "3l13.88 40.85213h8.39067l13.96133-40.20947v40.20947h9.55867v-57.88026h-11.99733"
                        }
                    />
                    <path 
                        d={
                            "M309.52133 212.06801h-9.592l-22.228 57.88026h10.472l16.568-46.0828 17.40533 46.0828h11.0" +
                            "6133l-23.68666-57.88026"
                        }
                    />
                    <path 
                        d={
                            "M379.60132 244.3784c-1.60266-2.3864-4.09866-4.3692-7.41333-5.89573-2.25067-1.024-6.208-2" +
                            ".17093-12.10533-3.5-6.89867-1.56147-9.32934-2.78813-10.15867-3.5464-1.136-1.01507-1.688-" +
                            "2.27346-1.688-3.85466 0-1.84947.816-3.4016 2.49467-4.74427 1.73466-1.39227 4.636-2.1 8.6" +
                            "2666-2.1 3.816 0 6.70267.7828 8.58534 2.32547 1.872 1.53853 2.94266 3.788 3.27733 6.8822" +
                            "6l.128 1.1604 9.12533-.68947-.0413-1.16519c-.13333-3.3536-1.084-6.40574-2.83067-9.0656-1" +
                            ".75866-2.67974-4.30133-4.73067-7.55066-6.10254-3.17067-1.336-6.868-2.01466-10.98534-2.01" +
                            "466-3.76 0-7.22133.64693-10.30133 1.91986-3.17067 1.30667-5.61867 3.264-7.27867 5.81614-" +
                            "1.66 2.54106-2.504 5.31973-2.504 8.25466 0 2.6828.69867 5.14533 2.07067 7.31973 1.36667 " +
                            "2.1656 3.448 3.9912 6.17867 5.4152 2.08933 1.10774 5.56933 2.23947 10.636 3.4572 4.87333" +
                            " 1.16934 8.03866 2.03334 9.40533 2.56667 2.02.76773 3.47067 1.70107 4.30533 2.77707.8133" + 
                            "4 1.04693 1.20934 2.24946 1.20934 3.66933 0 1.41453-.42134 2.68547-1.29334 3.88693-.8626" +
                            "6 1.20267-2.236 2.17454-4.084 2.88654-1.92.74946-4.184 1.12813-6.73066 1.12813-2.87334 0" +
                            "-5.48134-.5-7.75467-1.49227-2.208-.96146-3.848-2.21613-4.872-3.7344-1.048-1.55613-1.7266" +
                            "7-3.6-2.02-6.0708l-.136-1.164-8.98933.78694.0267 1.136c.0987 3.7932 1.156 7.2504 3.14267" +
                            " 10.2728 1.99067 3.02973 4.768 5.3272 8.25333 6.8272 3.41467 1.476 7.68 2.22133 12.67467" +
                            " 2.22133 3.98533 0 7.63067-.74533 10.844-2.2136 3.24933-1.4896 5.79067-3.6172 7.552-6.33" +
                            "493 1.75333-2.7156 2.64533-5.64894 2.64533-8.72134 0-3.10773-.82133-5.8984-2.444-8.3"
                        }
                    />
                    <path 
                        d={
                            "M386.05599 212.06801v9.0308h18.24933v48.84946h9.82534v-48.84946h18.31866v-9.0308h-46.39333"
                        }
                    />
                    <path 
                        d={
                            "M452.92132 260.91747V244.5612h30.64267v-8.99733h-30.64267v-14.46506h32.72133v-9.0308h-42" +
                            ".53999v57.88026h43.82133v-9.0308h-34.00267"
                        }
                    />
                    <path 
                        d={
                            "M533.01465 232.716c-.84266 1.30414-2.05066 2.23067-3.692 2.8348-1.74.63707-4.23866.9584-" +
                            "7.428.9584h-14.50533v-15.83079h16.284c3.764 0 6.52.74693 8.18133 2.21613 1.63734 1.45733" +
                            " 2.432 3.27093 2.432 5.53493 0 1.5584-.41466 2.96147-1.272 4.28653zm6.02 20.23334c-1.962" +
                            "66-3.0256-4.076-5.58294-6.288-7.61307-.468-.42027-1.01333-.83747-1.652-1.25787 3.908-.96" +
                            "773 6.952-2.59573 9.08534-4.85986 2.76133-2.924 4.16-6.55574 4.16-10.78907 0-3.26826-.83" +
                            "867-6.29893-2.48934-9.0016-1.67066-2.74266-3.95066-4.6864-6.77466-5.7864-2.73867-1.05733" +
                            "-6.56667-1.57346-11.70534-1.57346h-25.80533v57.88026h9.824v-24.59893h7.252c2.216 0 3.302" +
                            "67.13226 3.78533.2348.988.24373 1.98667.68226 2.96134 1.3052.97333.62813 2.124 1.77866 3" + 
                            ".41866 3.424 1.34 1.70933 3.09867 4.24786 5.216 7.5292l7.69734 12.10573h12.17733l-10.862" +
                            "67-16.99893"
                        }
                    />
                    <path 
                        d={
                            "M596.18265 244.3784c-1.604-2.3864-4.09866-4.3692-7.41466-5.89573-2.24934-1.024-6.20667-2" + 
                            ".17093-12.10267-3.5-6.90133-1.56147-9.33067-2.78813-10.16-3.5464-1.13733-1.01507-1.68933" +
                            "-2.27346-1.68933-3.85466 0-1.84947.816-3.4016 2.49466-4.74427 1.736-1.39227 4.63734-2.1 " +
                            "8.628-2.1 3.81467 0 6.70134.7828 8.584 2.32547 1.87334 1.53853 2.94267 3.788 3.27734 6.8" +
                            "8226l.128 1.1604 9.128-.68947-.0427-1.16519c-.132-3.3536-1.08533-6.40574-2.82933-9.0656-" +
                            "1.76134-2.67974-4.30267-4.73067-7.552-6.10254-3.16934-1.336-6.868-2.01466-10.98667-2.014" +
                            "66-3.75867 0-7.22133.64693-10.3 1.91986-3.17067 1.30667-5.61867 3.264-7.27867 5.81614-1." +
                            "66 2.54106-2.504 5.31973-2.504 8.25466 0 2.6828.69734 5.14533 2.06934 7.31973 1.368 2.16" +
                            "56 3.448 3.9912 6.17866 5.4152 2.09067 1.10774 5.572 2.23947 10.63734 3.4572 4.872 1.169" +
                            "34 8.03733 2.03334 9.40533 2.56667 2.01867.76773 3.47067 1.70107 4.30667 2.77707.81066 1" +
                            ".04693 1.20666 2.24946 1.20666 3.66933 0 1.41453-.41866 2.68547-1.292 3.88693-.86266 1.2" +
                            "0267-2.236 2.17454-4.08533 2.88654-1.91867.74946-4.184 1.12813-6.73067 1.12813-2.872 0-5" +
                            ".48-.5-7.75333-1.49227-2.208-.96146-3.84933-2.21613-4.87333-3.7344-1.04667-1.55613-1.725" +
                            "34-3.6-2.01867-6.0708l-.13733-1.164-8.98934.78694.0293 1.136c.0987 3.7932 1.15466 7.2504" +
                            " 3.14 10.2728 1.99066 3.02973 4.768 5.3272 8.25333 6.8272 3.416 1.476 7.68 2.22133 12.67" + 
                            "467 2.22133 3.98666 0 7.632-.74533 10.844-2.2136 3.25066-1.4896 5.79333-3.6172 7.552-6.3" +
                            "3493 1.75466-2.7156 2.64666-5.64894 2.64666-8.72134 0-3.10773-.82133-5.8984-2.444-8.3"
                        }
                    />
                </g>
        </g>
    </svg>
);

export default AppMastersLogo;
